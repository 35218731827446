import { Progress, Select, Tooltip } from 'antd';
import {
	Area,
	AreaChart,
	Bar,
	BarChart,
	CartesianGrid,
	Cell,
	Legend,
	Pie,
	PieChart,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from 'recharts';
import { agencies } from '../../../data';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMonthlyCollectionSummary } from '../../../features/fetch/fetchSlice';
import { useEffect } from 'react';
import useMeasure from 'react-use-measure';

export default function MonthlyCollectionSummaryGraph() {
	const dispatch = useDispatch();

	const { monthlyCollectionSummary } = useSelector((state) => state.fetch);

	const [chartRef, chartDimensions] = useMeasure();

	async function handleFetch() {
		await dispatch(fetchMonthlyCollectionSummary());
	}

	const dataFormater = (number) => {
		if (number > 1000000000) {
			return (number / 1000000000).toString() + 'B';
		} else if (number > 1000000) {
			return (number / 1000000).toString() + 'M';
		} else if (number > 1000) {
			return (number / 1000).toString() + 'K';
		} else {
			return number.toString();
		}
	};

	let percent;

	if (monthlyCollectionSummary?.deviation) {
		percent = Number(monthlyCollectionSummary?.deviation?.toFixed(2))
	} else {
		percent = 0.0;
	}

	useEffect(() => {
	}, [monthlyCollectionSummary]);

	useEffect(() => {
		handleFetch();
	}, []);

	return (
		<>
			<div
				style={{
					// width: '50%',
					maxWidth: '41vw',
					// width: '45rem',
					borderRadius: '0.9375rem',
					boxShadow: '0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)',
					height: 'inherit',
				}}
				className='flex flex-grow flex-col bg-white mt-[1.63rem] py-[1.34rem] px-[1rem]'>
				<h3 className='dash_bar_text_header'>Monthly collection summary</h3>
				<div className='h-full'>
					<div
						ref={chartRef}
						style={{
							borderRadius: '0.75rem',
							background: '#fff',
						}}
						className='flex flex-col h-full py-[1.25rem] px-[.56rem]'>
						<div className='flex justify-between h-full items-center pr-[4.06rem]'>
							<div className='dash_bar_text_header_3 flex items-center'>
								<span className='!text-[#48BB78]'>{percent}</span>
								<span className='ml-[.1rem]'>in June 2024</span>
							</div>
							<div className='flex items-center'>
								<div className='flex items-center'>
									<div
										style={{
											width: '0.75rem',
											height: '0.75rem',
											borderRadius: '0.875rem',
											background: '#1D453F',
										}}></div>
									<span className='bar_color_text !text-blk ml-[.38rem]'>
										Collection
									</span>
								</div>
								<div className='flex items-center ml-[1.31rem]'>
									<div
										style={{
											width: '0.75rem',
											height: '0.75rem',
											borderRadius: '0.875rem',
											background: '#9EC3BF',
										}}></div>
									<span className='bar_color_text !text-blk ml-[.38rem]'>
										Target
									</span>
								</div>
							</div>
						</div>
						{/* <ResponsiveContainer className='bar-char'> */}
						<AreaChart
							width={chartDimensions.width}
							height={650}
							data={monthlyCollectionSummary?.collectionsummary}
							// data={data}
							margin={{
								top: 10,
								right: 30,
								left: 0,
								bottom: 0,
							}}>
							<XAxis dy={5} dataKey='monthName' />
							<YAxis tickFormatter={dataFormater} type='number' tickSize={0} />
							<Tooltip />
							<Area
								type='monotone'
								dataKey='target'
								stackId='1'
								stroke='#2D3748'
								fill='#1B403B'
							/>
							<Area
								type='monotone'
								dataKey='value'
								stackId='1'
								stroke='#1D5161'
								fill='#8FB5B0'
							/>
						</AreaChart>
						{/* </ResponsiveContainer> */}
					</div>
				</div>
			</div>
		</>
	);
}
