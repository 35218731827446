import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from './layout/SideBar';
import axiosInstance from './instance';
import { useEffect } from 'react';
import { logout } from './features/auth/authSlice';

export const ProtectedRoute = ({ role }) => {
	const location = useLocation();
	const dispatch = useDispatch();

	const { isActive, token, user } = useSelector((state) => state.auth);

	axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

	const isTokenExpired = () => {
		const currentTime = Date.now() / 1000;

		if (user?.exp < currentTime) {
			alert("Session expired")
			return true;
		}

		return false;
	};

	async function handleLogout() {
		await dispatch(logout());
	}

	useEffect(() => {
		axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
	}, [token]);

	useEffect(() => {
		if (isTokenExpired()) {
			handleLogout();
		}
	}, [location.pathname]);

	if (!isActive) {
		return <Navigate to='/login' replace />;
	}

	return (
		<>
			<div className='flex mt-[.69rem]'>
				<SideBar role={role} />
				<div className='ml-[1.97rem] mr-[3.28rem] w-full'>
					{String(user?.user?.role)
						.toUpperCase()
						.includes(String(role).toUpperCase()) ? (
						<Outlet />
					) : (
						<Navigate to='/403-error' state={{ from: location }} replace />
					)}
				</div>
			</div>
		</>
	);
};
