export const timeInterval = [
	{
		label: '1 Day',
		value: 'T_1',
	},
	{
		label: '2 Days',
		value: 'T_2',
	},
	{
		label: '3 Days',
		value: 'T_3',
	},
	{
		label: '1 Week',
		value: 'T_7',
	},
    {
		label: '2 Weeks',
		value: 'T_8',
	},
];

export const banks = [
	{
		label: 'KCB BANK',
		value: 'KCB BANK',
	},
	{
		label: 'EQUITY BANK',
		value: 'EQUITY BANK',
	},
	{
		label: 'CO-OP BANK',
		value: 'CO-OP BANK',
	},
	{
		label: 'STANBIC BANK',
		value: 'STANBIC BANK',
	},
    {
		label: 'NATIONAL BANK',
		value: 'NATIONAL BANK',
	},
];

export const agencies = [
	{
		label: 'RTSA',
		value: 'RTSA',
	},
	{
		label: 'MINING',
		value: 'MINING',
	},
	{
		label: 'TRADE',
		value: 'TRADE',
	},
];

export const userRoles = [
	{
		label: 'ADMIN',
		value: 'ADMIN',
	},
	{
		label: 'STAFF',
		value: 'USER',
	},
];

export const userStatus = [
	{
		label: 'ACTIVE',
		value: 'ACTIVE',
	},
	{
		label: 'INACTIVE',
		value: 'INACTIVE',
	},
];
