import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function VerifyPhone() {
	const [form] = Form.useForm();
	const formRef = useRef(null);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [counter, setCounter] = useState({ minutes: 0, seconds: 59 });

	const onFinish = async (data) => {
		navigate('/login');
	};

	useEffect(() => {
		const interval = setInterval(() => {
			if (counter.minutes === 0 && counter.seconds === 0) {
				clearInterval(interval);
			} else {
				setCounter((prevCounter) => {
					if (prevCounter.seconds === 0) {
						return { minutes: prevCounter.minutes - 1, seconds: 59 };
					} else {
						return { ...prevCounter, seconds: prevCounter.seconds - 1 };
					}
				});
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [counter]);

	return (
		<>
			<div className='w-full h-full flex justify-center items-center'>
				<div className='flex flex-col mt-[0px]   lg:px-0 px-3'>
					<h2 className='heading_1 mt-[5rem] text-center'>Verify your phone number</h2>
					<p className='heading_5 mt-[1rem] text-center'>
                    We have sent an OTP to your phone
					</p>
					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={onFinish}
						style={{
							maxWidth: '100%',
						}}
						className='lg:mt-[78px] mt-3'
						form={form}>
						<div className='grid grid-cols-1 '>
							<Form.Item
								name='usrSecret'
								label={
									<span>
										Enter OTP sent to +260 111 111 122
									</span>
								}
								className='login-form-item '
								rules={[
									{
										required: true,
										message: 'Please add otp',
									},
								]}>
								<Input className='input-login' />
							</Form.Item>
						</div>

						<div className='mt-[22px] flex lg:flex-row flex-col-reverse items-center justify-center gap-x-3'>
							<div className='lg:w-[185px] w-full'>
								<Button
									className={`lg:w-[185px] mt-3 w-full rounded-[624.9375rem] !bg-[#fff] !text-[#374151] border border-[#D1D5DB] h-[38px] flex items-center justify-center px-2 py-3`}>
									Back
								</Button>
							</div>

							<div className='lg:w-[185px] w-full lg:mt-0'>
								<button type='submit' key='submit' className='cstm-btn'>
									Next
								</button>
							</div>
						</div>

						<div className='mt-[66px] flex items-center justify-center typography_p_2 gap-x-1'>
							Didn’t receive OTP?
							{counter.minutes === 0 && counter.seconds === 0 ? (
								<span
									className='font-[600] typography_p_2 !text-[#0057E3] cursor-pointer'
									disabled={counter.minutes > 0 && counter.seconds > 0}>
									Resend
								</span>
							) : (
								<span className='font-[600] typography_p_2'>
									{' '}
									Resend in {counter.minutes.toString().padStart(2, '0')}:
									{counter.seconds.toString().padStart(2, '0')}
								</span>
							)}
						</div>
					</Form>
				</div>
			</div>
		</>
	);
}
