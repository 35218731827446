import { useNavigate } from 'react-router-dom';

export default function Error403() {
	const navigate = useNavigate();

	return (
		<>
			<div className='w-full flex justify-center items-center h-[80vh]'>
				<div class='flex flex-col justify-center items-center bg-[#100] w-[600px] p-[3rem]'>
					<h1 class='heading_1 !text-[red]'>
						<code>Access Denied</code>
					</h1>
					<hr
						class='!mt-[1rem]'
						style={{
							margin: 'auto',
							width: '50%',
						}}
					/>
					<h3 class='mt-[1rem] !text-white label_2'>
						You dont have permission to view this page.
					</h3>
					<h3 class='mt-[1rem]'>🚫🚫🚫🚫</h3>
					<h6 class='mt-[1rem] label_1 !text-[red]'>
						<strong>Error Code</strong>: 403 forbidden
					</h6>

					<button
						onClick={() => navigate(-1)}
						className='flex items-center justify-center border border-white px-3 py-2 text-white mt-[5.05rem] rounded-[56px] w-[200px]'>
						Go Back
					</button>
				</div>
			</div>
		</>
	);
}
