import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../features/auth/authSlice';
import image2 from '../assets/img/image2.png';

export default function Header() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { user, isActive } = useSelector((state) => state.auth);

	async function handleLogout() {
		await dispatch(logout());
	}

	const items = [
		{
			label: (
				<>
					<div className='bg-white flex flex-col p-[1.25rem] min-w-[15.1875rem] h-auto'>
						<div className='flex items-start justify-start'>
							<img
								className='w-[3.0625rem] h-[3.0625rem] rounded-full object-cover'
								src={
									'https://simplyilm.com/wp-content/uploads/2017/08/temporary-profile-placeholder-1.jpg'
								}
								alt='profile avatar'
							/>

							<div className='flex flex-col ml-[.88rem]'>
								<span className='label_2'>{user?.user?.firstname} {user?.user?.lastname}</span>
								<span className='label_2'>{user?.sub}</span>
								<span className='reg_number_text mt-[.75rem]'>
								{user?.user?.role}
								</span>
							</div>
						</div>
						<div className='flex w-full justify-end mt-[1.56rem]'>
							<button
								onClick={handleLogout}
								className='flex items-center py-[.62rem] px-[1rem]'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 16 16'
									fill='none'>
									<path
										d='M7.5 13.5C7.5 13.6326 7.44732 13.7598 7.35355 13.8536C7.25979 13.9473 7.13261 14 7 14H3C2.86739 14 2.74021 13.9473 2.64645 13.8536C2.55268 13.7598 2.5 13.6326 2.5 13.5V2.5C2.5 2.36739 2.55268 2.24021 2.64645 2.14645C2.74021 2.05268 2.86739 2 3 2H7C7.13261 2 7.25979 2.05268 7.35355 2.14645C7.44732 2.24021 7.5 2.36739 7.5 2.5C7.5 2.63261 7.44732 2.75979 7.35355 2.85355C7.25979 2.94732 7.13261 3 7 3H3.5V13H7C7.13261 13 7.25979 13.0527 7.35355 13.1464C7.44732 13.2402 7.5 13.3674 7.5 13.5ZM14.3538 7.64625L11.8538 5.14625C11.7599 5.05243 11.6327 4.99972 11.5 4.99972C11.3673 4.99972 11.2401 5.05243 11.1462 5.14625C11.0524 5.24007 10.9997 5.36732 10.9997 5.5C10.9997 5.63268 11.0524 5.75993 11.1462 5.85375L12.7931 7.5H7C6.86739 7.5 6.74021 7.55268 6.64645 7.64645C6.55268 7.74021 6.5 7.86739 6.5 8C6.5 8.13261 6.55268 8.25979 6.64645 8.35355C6.74021 8.44732 6.86739 8.5 7 8.5H12.7931L11.1462 10.1462C11.0524 10.2401 10.9997 10.3673 10.9997 10.5C10.9997 10.6327 11.0524 10.7599 11.1462 10.8538C11.2401 10.9476 11.3673 11.0003 11.5 11.0003C11.6327 11.0003 11.7599 10.9476 11.8538 10.8538L14.3538 8.35375C14.4002 8.30731 14.4371 8.25217 14.4623 8.19147C14.4874 8.13077 14.5004 8.06571 14.5004 8C14.5004 7.93429 14.4874 7.86923 14.4623 7.80853C14.4371 7.74783 14.4002 7.69269 14.3538 7.64625Z'
										fill='#212121'
									/>
								</svg>
								<span className='profile_text ml-[.75rem]'>Log out</span>
							</button>
						</div>
					</div>
				</>
			),
			key: '0',
		},
	];

	return (
		<>
			<div
				style={{
					height: '3.875rem',
					display: 'flex',
					padding: '0.5rem 1.875rem',
					alignItems: 'center',
					background: '#FFF',
				}}
				className='w-full header items-center justify-between'>
				<div
					className='flex items-center cursor-pointer'
					onClick={() => navigate('/')}>
					<img src={image2} className='zambia-logo' alt='image2' />

					<div className='mx-[.62rem]'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='2'
							height='38'
							viewBox='0 0 2 38'
							fill='none'>
							<path
								d='M0 0.612305H1.67164V12.3138H0V0.612305Z'
								fill='#D81F11'
							/>
							<path d='M0 13.1494H1.67164V24.8509H0V13.1494Z' fill='black' />
							<path d='M0 25.6865H1.67164V37.388H0V25.6865Z' fill='#E77903' />
						</svg>
					</div>
					<span className='zambia_logo_text heading_4 !text-blk21 flex items-center'>
						<span className='text-[#34B749]'>e</span>
						Zambia
					</span>
				</div>

				{isActive ? (
					<div className='flex items-center gap-x-[1.25rem]'>
						<div className='pr-[1.25rem] cursor-pointer'>
							<Dropdown
								menu={{
									items,
								}}
								trigger={['click']}>
								<img
									className='w-[3.0625rem] h-[3.0625rem] rounded-full object-cover'
									src={
										'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Missing_avatar.svg/2048px-Missing_avatar.svg.png'
									}
									alt='profile avatar'
								/>
							</Dropdown>
						</div>
					</div>
				) : null}
			</div>
		</>
	);
}
