import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

export default function InvoiceChart() {
	const { instTransactions } = useSelector((state) => state.fetch);

	let arrayCopy = [...instTransactions]
		?.sort((a, b) => b?.invoiceSettlementRate - a?.invoiceSettlementRate)
		?.slice(0, 4);

	const COLORS = ['#575757', '#B1E3FF', '#B6E3A1', '#EA9D2C'];

	const combinedArray = arrayCopy.map((transaction, index) => ({
		...transaction,
		color: COLORS[index % COLORS.length],
	}));

	useEffect(() => {}, [instTransactions]);

	return (
		<>
			<div className='flex flex-col flex-grow'>
				<h3 className='dash_card_cash_text_header'>Invoices</h3>
				<div className='branch-chart-card mt-[.44rem]'>
					<div className='flex h-full items-center justify-center'>
						<ResponsiveContainer>
							<PieChart>
								<Pie
									data={combinedArray}
									cx={120}
									cy={200}
									innerRadius={60}
									outerRadius={80}
									fill='#8884d8'
									paddingAngle={2}
									dataKey='invoiceSettlementRate'>
									{combinedArray?.map((item, index) => (
										<Cell key={item?.id} fill={item?.color} />
									))}
								</Pie>
							</PieChart>
						</ResponsiveContainer>
					</div>

					<div className='flex justify-center flex-grow items-center w-full'>
						<div className='flex items-center w-auto'>
							<div className='flex flex-col p-[2rem]'>
								<span className='regular_12'>Institution</span>
								<div className='ml-[.5rem] flex flex-col'>
									{combinedArray?.map((item) => {
										return (
											<>
												<div className='flex items-center mt-[.88rem]'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='16'
														height='16'
														viewBox='0 0 16 16'
														fill='none'>
														<path
															d='M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8Z'
															fill={`${item?.color}`}
														/>
													</svg>
													<span className='ml-[.1rem] regular_8'>
														{item?.institutionName}
													</span>
												</div>
											</>
										);
									})}
								</div>
							</div>

							<div className='flex flex-col p-[2rem]'>
								<span className='regular_12'>Success rate</span>

								<div className='ml-[.5rem] flex flex-col'>
									{combinedArray?.map((item) => {
										return (
											<>
												<div className='flex items-center mt-[.88rem]'>
													<span className='ml-[.1rem] regular_8 text-right w-full'>
														{item?.invoiceSettlementRate}%
													</span>
												</div>
											</>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
