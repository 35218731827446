import { Table, Tag } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInstTransactions } from '../../../features/fetch/fetchSlice';

export default function InstitutionTransactionTable() {
	const dispatch = useDispatch();
	const { instTransactions } = useSelector((state) => state.fetch);

	async function handleFetch() {
		await dispatch(fetchInstTransactions());
	}

	useEffect(() => {
		handleFetch();
	}, []);

	useEffect(() => {}, [instTransactions]);

	const columns = [
		{
			title: 'Institution',
			key: 'institutionName',
			dataIndex: 'institutionName',
			render: (item) => (
				<span className='paragraph_2 !text-[#101828]'>{item}</span>
			),
		},
		{
			title: 'Payments',
			key: 'id',
			render: (item) => (
				<>
					<div className='flex items-center gap-x-[1.5rem]'>
						<div className='flex flex-col'>
							<span className='table_inner_header'>Success</span>
							<span className='paragraph_2 !text-[#101828] mt-[.5rem]'>
								{item?.passedPayments}
							</span>
						</div>

						<div className='flex flex-col'>
							<span className='table_inner_header'>Failed</span>
							<span className='paragraph_2 !text-[#101828] mt-[.5rem]'>
								{item?.failedPayment}
							</span>
						</div>
					</div>
				</>
			),
		},
		{
			title: 'Payments Success rate',
			key: 'paymentSuccessRate',
			dataIndex: 'paymentSuccessRate',
			render: (item) => (
				<span className='paragraph_2 !text-[#101828]'>{item}%</span>
			),
		},
		{
			title: 'Withdrawals',
			key: 'id',
			render: (item) => (
				<>
					<div className='flex items-center gap-x-[1.5rem]'>
						<div className='flex flex-col'>
							<span className='table_inner_header'>Success</span>
							<span className='paragraph_2 !text-[#101828] mt-[.5rem]'>
								{item?.withdrawalSuccess}
							</span>
						</div>

						<div className='flex flex-col'>
							<span className='table_inner_header'>Failed</span>
							<span className='paragraph_2 !text-[#101828] mt-[.5rem]'>
								{item?.withdrawalFailed}
							</span>
						</div>
					</div>
				</>
			),
		},
		{
			title: 'Withdrawals Success rate',
			dataIndex: 'withdrawalSuccRate',
			key: 'withdrawalSuccRate',
			render: (item) => (
				<span className='paragraph_2 !text-[#101828]'>{item}%</span>
			),
		},
		{
			title: 'Invoices',
			key: 'id',
			render: (item) => (
				<>
					<div className='flex items-center gap-x-[1.5rem]'>
						<div className='flex flex-col'>
							<span className='table_inner_header'>Settled</span>
							<span className='paragraph_2 !text-[#101828] mt-[.5rem]'>
								{item?.invoicesSettled}
							</span>
						</div>

						<div className='flex flex-col'>
							<span className='table_inner_header'>Pending</span>
							<span className='paragraph_2 !text-[#101828] mt-[.5rem]'>
								{item?.invoicesPending}
							</span>
						</div>
					</div>
				</>
			),
		},
		{
			title: 'Invoices Settle rate',
			dataIndex: 'invoiceSettlementRate',
			key: 'invoiceSettlementRate',
			render: (item) => (
				<span className='paragraph_2 !text-[#101828]'>{item}%</span>
			),
		},
		{
			title: 'Action',
			key: 'action',
			render: (item) => (
				<button
					// onClick={() => handleViewApplication(item)}
					className='view_btn'>
					View
				</button>
			),
		},
	];

	return (
		<>
			<div className='flex flex-col'>
				<div className='flex justify-between items-center'>
					<span className='dash-head'>Transactions by Institution</span>
				</div>

				<div className=''>
					<section>
						<Table
							className='mt-[1.31rem]'
							// scroll={{
							// 	x: 800,
							// }}
							pagination={{
								defaultPageSize: 5,
								hideOnSinglePage: true,
								pageSizeOptions: [10, 20, 50, 100],
							}}
							columns={columns}
							dataSource={instTransactions}
						/>
					</section>
				</div>
			</div>
		</>
	);
}
