import { createBrowserRouter } from 'react-router-dom';
import Login from './pages/auth/Login';
import Index from './pages/dashboard/Index';
import { ProtectedRoute } from './ProtectedRoute';
import Root from './Root';
import Register from './pages/auth/Register';
import VerifyPhone from './pages/auth/VerifyPhone';
import VerifyEmail from './pages/auth/VerifyEmail';
import Institutions from './pages/institutions/Index';
import UserManagement from './pages/setup/UserManagement';
import BranchManagement from './pages/setup/BranchManagement';
import AddBranch from './pages/setup/AddBranch';
import Error403 from './pages/error_pages/403Page';
import InstitutionDashboard from './pages/dashboard/InstitutionDashboard';

export const router = createBrowserRouter([
	{
		path: '/',
		element: <Root />,
		children: [
			{
				path: '/',
				element: <Login />,
				index: true,
			},
			{
				path: '/login',
				element: <Login />,
			},

			{
				path: '/phone-verification',
				element: <VerifyPhone />,
			},
			{
				path: '/email-verification',
				element: <VerifyEmail />,
			},
			{
				path: '/403-error',
				element: <Error403 />,
			},
			{
				element: <ProtectedRoute role={'SPA'} />,
				children: [
					{
						path: '/spa/dashboard',
						element: <Index />,
					},
					{
						path: '/onboarding-new-institution',
						element: <Register />,
					},
					{
						path: '/institutions',
						element: <Institutions />,
					},
				],
			},
			{
				element: <ProtectedRoute role={'ADMIN'} />,
				children: [
					{
						path: '/admin/dashboard',
						element: <InstitutionDashboard />,
					},
					{
						path: '/setup/users',
						element: <UserManagement />,
					},
					{
						path: '/setup/branches',
						element: <BranchManagement />,
					},
					{
						path: '/setup/create-branch',
						element: <AddBranch />,
					},
				],
			},
			{
				element: <ProtectedRoute role={'MANAGER'} />,
				children: [
					{
						path: '/executive/dashboard',
						element: <Index />,
					},
				],
			},
			{
				element: <ProtectedRoute role={'USER'} />,
				children: [
					{
						path: '/user/dashboard',
						element: <Index />,
					},
				],
			},
			{
				path: '*',
				element: <p>404 - Error: Page not found</p>,
			},
		],
	},
]);
