import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { Form, Input, Spin } from 'antd';
import { login, setAuth } from '../../features/auth/authSlice';
import { useRef } from 'react';
import toast from 'react-hot-toast';
import axiosInstance from '../../instance';
import { jwtDecode } from 'jwt-decode';

export default function Login() {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { authLoading, isActive, user } = useSelector((state) => state.auth);

	const formRef = useRef(null);

	const onFinish = async (data) => {
		const res = await dispatch(login(data));
		if (res?.payload?.success) {
			axiosInstance.defaults.headers.common['Authorization'] =
				await `Bearer ${res?.payload?.access_token}`;

			const decodedObj = await jwtDecode(res?.payload?.access_token);

			const authObj = {
				token: res?.payload?.access_token,
				refresh: res?.payload?.refresh_token,
				user: decodedObj,
			};

			// console.log(authObj);

			await dispatch(setAuth(authObj));

			toast.success('Login successful');
		} else {
			toast.error('Invalid username or password');
		}
	};

	if (isActive) {
		return (
			<Navigate
				to={
					user?.user?.role === 'SPA'
						? '/spa/dashboard'
						: user?.user?.role === 'ADMIN'
						? '/admin/dashboard'
						: user?.user?.role === 'MANAGER'
						? '/executive/dashboard'
						: '/user/dashboard'
				}
				replace
			/>
		);
	}

	return (
		<>
			<div className='flex w-full h-full'>
				<div className='w-[36.1875rem] h-[100vh]  login-img relative lg:block hidden'>
					<div className='absolute bottom-0 login-overlay'>
						<div className='flex flex-col w-[29rem]'>
							<span className='heading_4'>
								All government services at your fingertips
							</span>
							<span className='paragraph_1 mt-[1.25rem]'>
								Submit government forms, make payments online, and check your
								application status
							</span>

							<div className='flex w-full items-center justify-end mt-[1.25rem]'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='11'
									height='11'
									viewBox='0 0 11 11'
									fill='none'>
									<circle cx='5.5' cy='5.5' r='5' fill='#388E3C' />
								</svg>

								<svg
									className='mx-[.31rem]'
									xmlns='http://www.w3.org/2000/svg'
									width='11'
									height='11'
									viewBox='0 0 11 11'
									fill='none'>
									<circle cx='5.5' cy='5.5' r='5' fill='white' />
								</svg>

								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='11'
									height='11'
									viewBox='0 0 11 11'
									fill='none'>
									<circle cx='5.5' cy='5.5' r='5' fill='white' />
								</svg>
							</div>
						</div>
					</div>
				</div>

				<div className='flex flex-col justify-center items-center h-full w-full py-[3.5rem] lg:px-0 px-3'>
					{/* <div className='flex items-center cursor-pointer' onClick={home}>
						<img src={image2} className='zambia-logo' alt='image2' />
						<span className='zambia_logo_text ml-[1.23rem]'>ECITIZEN</span>
					</div> */}
					<span className='heading_1 mt-[2.94rem]'>Login</span>
					<span className='zambia_login_text mt-[.75rem]'>
						All Government Services{' '}
					</span>

					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={onFinish}
						style={{
							maxWidth: '100%',
							width: '24rem',
							marginTop: '3.75rem',
						}}
						form={form}>
						<Form.Item
							label='Email address or ID number  *'
							className='login-form-item'
							name='email'
							rules={[
								{
									required: true,
									message: 'Please input your email',
								},
							]}>
							<Input className='input-login' />
						</Form.Item>
						<Form.Item
							label='Password'
							className='login-form-item'
							name='password'
							rules={[
								{
									required: true,
									message: 'Please input your password',
								},
							]}>
							<Input.Password className='input-login' type='password' />
						</Form.Item>

						{/* <span className='dnt_have_account_text'>
							Are you a new Institution?{' '}
							<Link className='forgot_text' to='/registration'>Create an account here</Link>
						</span> */}

						<button
							disabled={authLoading}
							className='cstm-btn mt-[3.5rem]'
							type='submit'>
							{authLoading ? <Spin /> : 'Login'}
						</button>
					</Form>
				</div>
			</div>
		</>
	);
}
