import { notification } from 'antd';
import toast from 'react-hot-toast';

export const openNotificationWithIcon = (alertObj) => {
	notification[alertObj.type]({
		duration: 7,
		placement: 'bottomLeft',
		stack: true,
		threshold: 1,
		key: alertObj.key,
		message: alertObj.title,
		description: alertObj.content,
	});
};

export const customToast = (toastObj) => {
	toast.custom((t) => (
		<div
			style={{
				border: `2px solid ${
					toastObj.bdColor === 'error'
						? '#C8001B'
						: toastObj.bdColor === 'success'
						? '#02A548'
						: '#285ff6'
				}`,
			}}
			className={`max-w-md w-full bg-white rounded-[3px] flex`}>
			<div className='flex-1 w-0 p-4'>
				<div className='flex items-start'>
					{toastObj?.img ? (
						<>
							<div className='pt-0.5'>
								<img
									className='h-10 w-10 rounded-full'
									src='https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=6GHAjsWpt9&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80'
									alt=''
								/>
							</div>
						</>
					) : null}

					<div className='ml-3 flex-1'>
						{toastObj?.name ? (
							<>
								<p className='mt-1 text-[21px] text-blk3 font-bold'>
									{toastObj?.name}
								</p>
							</>
						) : null}
						<p className='mt-1 text-[18px] text-blk3 font-semibold'>
							{toastObj?.content}
						</p>
					</div>
				</div>
			</div>
		</div>
	));
};

export const formatMoney = (money) => {
	let currency = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'ZMW',
	});
	if (!money) {
		return 'ZK 0.00';
	}
	return currency.format(money);
};

export const obfuscateEmail = (email) => {
	const [username, domain] = email?.split('@');

	if (!username || !domain) {
		throw new Error('Invalid email format');
	}
	const obfuscatedUsername = username?.substring(0, 2) + '****';
	return `${obfuscatedUsername}@${domain}`;
};

export const addSpaces = (number) => {
	const numberStr = number.toString();
	const formattedStr = numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	return formattedStr;
};

export const percentageCollected = (collectedMoney, target) => {
	if (target === 0) {
		return 0;
	}
	return (collectedMoney / target) * 100;
};
