import React, { useEffect, useRef } from 'react';
import { Form, Input, Spin } from 'antd';
import svg4 from '../../../assets/svg/svg4.svg';
import PhoneInput from 'react-phone-input-2';
import { useSelector } from 'react-redux';

export default function AdminStep({ error, prev }) {
	const { saving } = useSelector((state) => state.save);

	const ref = useRef();

	useEffect(() => {
		if (error) {
			ref.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'start',
			});
		}
	}, [error]);

	return (
		<>
			<div
				ref={ref}
				className='flex flex-col items-center justify-center w-full'>
				<div className='mt-[1.44rem]  p-[3.5rem] flex flex-col justify-center w-full bg-white'>
					<div className='flex items-center'>
						<div className='w-[3.45rem]'>
							<div className='bg-[#EDF8FF] w-[3.5rem] h-[3.5rem] rounded-full flex justify-center items-center'>
								<span className='paragraph_paragraph_bb08'>4</span>
							</div>
						</div>

						<span className='heading_5 ml-[1.31rem]'>Admin Details</span>
					</div>
					<div className='mt-[2.25rem] px-[1.37rem] flex flex-col items-start justify-start w-full'>
						{error ? (
							<div className='flex items-center mb-[.75rem]'>
								<img src={svg4} alt='info icon' />
								<span className='forgot_text !text-[red] ml-[.5rem] mt-[.5px]'>
									Fill all reqired fields before proceeding
								</span>
							</div>
						) : null}
						<div className='grid grid-cols-1 lg:grid-cols-2 gap-5 w-full'>
							<Form.Item
								name='firstname'
								label='First Name'
								rules={[
									{
										required: false,
										message: 'Please select document type',
									},
								]}>
								<Input className='input' />
							</Form.Item>

							<Form.Item
								name='lastname'
								label='Last Name'
								rules={[
									{
										required: false,
										message: 'Please add office phone number',
									},
								]}>
								<Input className='input' />
							</Form.Item>

							<Form.Item
								validateTrigger={['onBlur']}
								name='email'
								label='Email'
								rules={[
									{
										required: false,
										message: 'Please select document type',
									},
									{
										type: 'email',
										message: 'Enter a valid email address',
									},
								]}>
								<Input className='input' />
							</Form.Item>

							<Form.Item
								name='phoneNumber'
								label='Phone Number'
								rules={[
									{
										required: false,
										message: 'Please add office phone number',
									},
								]}>
								<PhoneInput
									country='zm'
									countryCodeEditable={false}
									className='input'
								/>
							</Form.Item>
						</div>
					</div>
				</div>

				<div className='w-full flex flex-col justify-center items-center'>
					<div className='text-center mt-10 w-[299px]'>
						<button type='submit' className='cstm-btn'>
							{saving ? <Spin /> : 'Submit'}
						</button>
					</div>

					<div className='mt-[20px] mb-[50px] w-[299px]'>
						<button
							type='button'
							className='cstm-btn-borderless'
							onClick={() => prev()}>
							Back
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
