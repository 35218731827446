import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	authLoading: false,
	user: {},
	isActive: false,
	token: '',
	refresh: '',
};

export const login = createAsyncThunk('authSlice/login', async (data) => {
	const res = await axios
		.post(`${url}/auth/login`, data)
		.then((res) => res.data);
	return res;
});

export const updateUser = createAsyncThunk(
	'authSlice/updateUser',
	async (data) => {
		const res = await axiosInstance
			.post(`${url}/auth/saveUser.action`, data)
			.then((res) => res.data);
		return res;
	}
);

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: (state) => {
			state.user = {};
			state.isActive = false;
			state.token = '';
			state.refresh = ''
			localStorage.clear();
		},
		setAuth: (state, action) => {
			state.token = action.payload.token;
			state.refresh = action.payload.refresh
			state.isActive = true;
			state.user = action.payload.user;
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(updateUser.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(updateUser.fulfilled, (state, action) => {
				state.authLoading = false;
			})
			.addCase(updateUser.rejected, (state) => {
				state.authLoading = false;
			})

			.addCase(login.pending, (state) => {
				state.authLoading = true;
			})
			.addCase(login.fulfilled, (state, action) => {
				state.authLoading = false;
			})
			.addCase(login.rejected, (state) => {
				state.authLoading = false;
			});
	},
});

export default authSlice.reducer;
export const { logout, setAuth } = authSlice.actions;
