import { Table } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchBranches } from '../../features/fetch/fetchSlice';

export default function BranchManagement() {
	const dispatch = useDispatch();

	const { branches, loading } = useSelector((state) => state.fetch);

	console.log("branches", branches);

	async function handleFetch() {
		await dispatch(fetchBranches());
	}

	const columns = [
		{
			title: 'Branch Name',
			dataIndex: 'brnName',
		},
		{
			title: 'Abbreviation',
			dataIndex: 'brnCode',
		},
		{
			title: 'KRA PIN',
			dataIndex: 'brnKraPin',
			key: 'brnKraPin',
		},
		{
			title: 'Business Reg No.',
			dataIndex: 'brnRegCertNo',
			key: 'brnRegCertNo',
		},
		{
			title: 'Branch Email',
			dataIndex: 'brnEmail',
			key: 'brnEmail',
		},
		{
			title: 'Branch Phone',
			dataIndex: 'brnCellPhone',
			key: 'brnCellPhone',
		},
		{
			title: 'Action',
			key: 'action',
			render: (item) => (
				<button
					// onClick={() => handleViewApplication(item)}
					className='view_btn'>
					View
				</button>
			),
		},
	];

	useEffect(() => {
	}, [branches]);

	useEffect(() => {
		handleFetch()
	}, []);

	return (
		<>
			<div className='mt-[52.5px]'>
				<div className='flex justify-between items-center w-full'>
					<span className='dash-head'>All Branches</span>
					<Link to='/setup/create-branch' className='green-border-btn !w-fit'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='25'
							height='24'
							viewBox='0 0 25 24'
							fill='none'>
							<path
								fill-rule='evenodd'
								clip-rule='evenodd'
								d='M3.2207 12C3.2207 11.5858 3.55649 11.25 3.9707 11.25H20.4707C20.8849 11.25 21.2207 11.5858 21.2207 12C21.2207 12.4142 20.8849 12.75 20.4707 12.75H3.9707C3.55649 12.75 3.2207 12.4142 3.2207 12Z'
								fill='#2E7D32'
							/>
							<path
								fill-rule='evenodd'
								clip-rule='evenodd'
								d='M12.2207 3C12.6349 3 12.9707 3.33579 12.9707 3.75V20.25C12.9707 20.6642 12.6349 21 12.2207 21C11.8065 21 11.4707 20.6642 11.4707 20.25V3.75C11.4707 3.33579 11.8065 3 12.2207 3Z'
								fill='#2E7D32'
							/>
						</svg>
						<span className='ml-[.5rem]'>Add Branch</span>
					</Link>
				</div>
				<Table
					loading={loading}
					className='mt-[44.5px]'
					pagination={false}
					columns={columns}
					dataSource={branches}
				/>

				{/* <div className='w-full flex justify-center items-center mt-[7rem]'>
					<div className='flex flex-col w-[200px]'>
						<Link to="/dashboard" className='cstm-btn'>
							Finish
						</Link>

                        <button onClick={() => prev()} className='cstm-btn-borderless mt-[1rem]'>
							Prev
						</button>
					</div>
				</div> */}
			</div>
		</>
	);
}
