import { Progress, Select, Tooltip } from 'antd';
import { Bar, BarChart, XAxis, YAxis } from 'recharts';
import { agencies } from '../../../data';
import useMeasure from 'react-use-measure';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCollectionPerService } from '../../../features/fetch/fetchSlice';
import { useEffect } from 'react';
import { formatMoney, percentageCollected } from '../../../utils';

export default function CollectionPerServiceGraph() {
	const dispatch = useDispatch();

	const { collectionPerService } = useSelector((state) => state.fetch);

	let arrayCopy = collectionPerService?.collectionPerService && [...collectionPerService?.collectionPerService]?.slice(0, 4);

	const data = arrayCopy?.map(item => {
		const remainingTarget = item?.target - item?.collected > 0 ? item?.target - item?.collected : 0;
		const collected = item?.collected > item?.target ? item?.target : item?.collected;
		return {
		  ...item,
		  remainingTarget,
		  collected
		};
	  });

	const [chartRef, chartDimensions] = useMeasure();

	async function handleFetch() {
		await dispatch(fetchCollectionPerService());
	}

	const dataFormater = (number) => {
		if (number > 1000000000) {
			return (number / 1000000000).toString() + 'B';
		} else if (number > 1000000) {
			return (number / 1000000).toString() + 'M';
		} else if (number > 1000) {
			return (number / 1000).toString() + 'K';
		} else {
			return number.toString();
		}
	};

	let percent;

	if (collectionPerService?.deviation) {
		percent = Number(collectionPerService?.deviation?.toFixed(2));
	} else {
		percent = 0.0;
	}

	useEffect(() => {
	}, [collectionPerService]);

	useEffect(() => {
		handleFetch();
	}, []);

	return (
		<>
			<div
				style={{
					// minWidth: '40.75rem',
					// width: 'auto',
					// maxWidth: '100%',
					// width: '40%',
					width: '38rem',
					borderRadius: '0.9375rem',
					boxShadow: '0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)',
				}}
				className='flex flex-grow h-full flex-col bg-white mt-[1.63rem] py-[1.34rem] px-[1rem]'>
				<h3 className='dash_bar_text_header'>Collections per service</h3>
				<div className='mt-[.44rem]'>
					<div
						ref={chartRef}
						style={{
							borderRadius: '0.75rem',
							background:
								'linear-gradient(77deg, #313860 -8.94%, #151928 24.18%)',
						}}
						className='flex flex-col h-full py-[1.25rem] px-[.56rem]'>
						<div className='flex justify-between items-center pr-[4.06rem] pl-[5.25rem]'>
							<div className='w-fit min-w-[5rem]'>
								<Select
									dropdownStyle={{ width: 'fit-content' }}
									className='collection-overview-select'
									defaultValue={'RTSA'}
									suffixIcon={
										<svg
											className='mx-[.38rem]'
											xmlns='http://www.w3.org/2000/svg'
											width='10'
											height='7'
											viewBox='0 0 10 7'
											fill='none'>
											<path
												d='M9 1.5L5 5.5L1 1.5'
												stroke='#212121'
												stroke-width='2'
												stroke-linecap='round'
												stroke-linejoin='round'
											/>
										</svg>
									}
									showSearch
									optionFilterProp='children'
									filterOption={(input, option) =>
										(option?.label?.toLocaleLowerCase() ?? '').includes(
											input?.toLocaleLowerCase()
										)
									}
									filterSort={(optionA, optionB) =>
										(optionA?.label ?? '')
											.toLowerCase()
											.localeCompare((optionB?.label ?? '').toLowerCase())
									}
									options={agencies?.map((item) => {
										return {
											label: item?.label,
											value: item?.value,
										};
									})}
								/>
							</div>
							<div className='flex items-center'>
								<div className='flex items-center'>
									<div
										style={{
											width: '0.75rem',
											height: '0.75rem',
											borderRadius: '0.875rem',
											background: '#136B38',
										}}></div>
									<span className='bar_color_text ml-[.38rem]'>Collection</span>
								</div>
								<div className='flex items-center ml-[1.31rem]'>
									<div
										style={{
											width: '0.75rem',
											height: '0.75rem',
											borderRadius: '0.875rem',
											background: '#fff',
										}}></div>
									<span className='bar_color_text ml-[.38rem]'>Target</span>
								</div>
							</div>
						</div>
						{/* <ResponsiveContainer className='bar-char'> */}
						<BarChart
							className='mt-[1.5rem]'
							barSize={20}
							width={chartDimensions.width}
							height={505}
							// data={arrayCopy}
							data={data}
							margin={{
								top: 20,
								right: 30,
								left: 20,
								bottom: 5,
							}}>
							<XAxis dataKey='serviceName' padding={{ left: 0, right: 0 }} />
							<YAxis tickFormatter={dataFormater} type='number' tickSize={0} />
							<Tooltip />
							<Bar dataKey='collected' stackId='a' fill='#136B38' />
							<Bar dataKey='remainingTarget' stackId='a' fill='#EFFFEF' />
						</BarChart>
						{/* </ResponsiveContainer> */}
					</div>
					<h3 className='dash_bar_text_header_2 mt-[.5rem]'>Top Performers</h3>
					<div className='mt-[.38rem] dash_bar_text_header_3 flex items-center'>
						<span className='!text-[#48BB78]'>(+20) </span>
						<span className='ml-[.1rem]'>increase from last week</span>
					</div>

					<div className='mt-[1.25rem] flex justify-between items-center px-[.75rem] gap-[1.5rem] flex-wrap'>
						{arrayCopy?.map((item) => {
							return (
								<>
									<div className='flex flex-col'>
										<span className='dash_bar_text_header_4'>
											{item?.serviceName}
										</span>
										<span className='dash_bar_text_header_5 mt-[.78rem]'>
											{formatMoney(item?.collected)}
										</span>

										<div className='w-[100px]'>
											<Progress
												strokeColor={'#34B749'}
												trailColor={'#E2E8F0'}
												showInfo={false}
												percent={percentageCollected(
													item?.collected,
													item?.target
												)}
												size={['100%', 7]}
											/>
										</div>
									</div>
								</>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
}
