import React, { useEffect, useRef } from 'react';
import { Form, Input } from 'antd';
import PhoneInput from 'react-phone-input-2';
import svg4 from '../../../assets/svg/svg4.svg';

export default function AgencyStep({ next, error }) {
	const ref = useRef();

	useEffect(() => {
		if (error) {
			ref.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'start',
			});
		}
	}, [error]);

	return (
		<>
			<div
				ref={ref}
				className='flex flex-col items-center justify-center w-full'>
				<div className='mt-[1.44rem]  p-[3.5rem] flex flex-col justify-center w-full bg-white'>
					<div className='flex items-center'>
						<div className='w-[3.45rem]'>
							<div className='bg-[#EDF8FF] w-[3.5rem] h-[3.5rem] rounded-full flex justify-center items-center'>
								<span className='paragraph_paragraph_bb08'>1</span>
							</div>
						</div>

						<span className='heading_5 ml-[1.31rem]'>Institution Details</span>
					</div>
					<div className='mt-[2.25rem] px-[1.37rem] flex flex-col items-start justify-start '>
						{error ? (
							<div className='flex items-center mb-[.75rem]'>
								<img src={svg4} alt='info icon' />
								<span className='forgot_text !text-[red] ml-[.5rem] mt-[.5px]'>
									Fill all reqired fields before proceeding
								</span>
							</div>
						) : null}
						<div className='grid grid-cols-1 w-full'>
							<Form.Item
								name='instName'
								label='Institution Name'
								rules={[
									{
										required: true,
										message: 'Please add first name',
									},
								]}>
								<Input className='input' />
							</Form.Item>

							<Form.Item
								name='instCode'
								label='Institution Name Abbreviation'
								rules={[
									{
										required: true,
										message: 'Please add organization abbreviation code',
									},
								]}>
								<Input className='input' />
							</Form.Item>

							<Form.Item
								validateTrigger='onBlur'
								name='instWebsite'
								label='Website'
								rules={[
									{
										required: true,
										message: 'Add existing url to your website',
									},
									{
										type: 'url',
										message: 'Enter valid url',
									},
								]}>
								<Input className='input' />
							</Form.Item>

							<Form.Item
								name='instKraPin'
								label='KRA PIN'
								rules={[
									{
										required: true,
										message: 'Institution KRA PIN is reqired',
									},
								]}>
								<Input className='input' />
							</Form.Item>

							<Form.Item
								name='instRegCertNo'
								label='Business Registration Number'
								rules={[
									{
										required: false,
										message: 'Please add email',
									},
								]}>
								<Input className='input' />
							</Form.Item>

							<Form.Item
								validateTrigger={['onBlur']}
								name='instEmail'
								label='Work Email'
								rules={[
									{
										required: false,
										message: 'Please add email',
									},
									{
										type: 'email',
										message: 'Enter a valid email address',
									},
								]}>
								<Input type='email' className='input' />
							</Form.Item>

							<Form.Item
								name='instCellPhone'
								label='Work Phone'
								rules={[
									{
										required: false,
										message: 'Please add office phone number',
									},
								]}>
								<PhoneInput
									country='ke'
									onlyCountries={['ke']}
									countryCodeEditable={false}
									className='input'
								/>
							</Form.Item>
						</div>
					</div>
				</div>

				<div className='w-full flex flex-col justify-center items-center'>
					<div className='text-center mt-10 w-[299px]'>
						<button onClick={() => next()} type='button' className='cstm-btn'>
							Next
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
