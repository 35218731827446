import React, { useEffect, useRef } from 'react';
import { Form, Input } from 'antd';
import PhoneInput from 'react-phone-input-2';
import svg4 from '../../../assets/svg/svg4.svg';

export default function AgencyBranchStep({ next, error }) {
	const ref = useRef();

	useEffect(() => {
		if (error) {
			ref.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'start',
			});
		}
	}, [error]);

	return (
		<>
			<div
				ref={ref}
				className='flex flex-col items-center justify-center w-full'>
				<div className='mt-[1.44rem]  p-[3.5rem] flex flex-col justify-center w-full bg-white'>
					<div className='flex items-center'>
						<div className='w-[3.45rem]'>
							<div className='bg-[#EDF8FF] w-[3.5rem] h-[3.5rem] rounded-full flex justify-center items-center'>
								<span className='paragraph_paragraph_bb08'>1</span>
							</div>
						</div>

						<span className='heading_5 ml-[1.31rem]'>Branch Details</span>
					</div>
					<div className='mt-[2.25rem] px-[1.37rem] flex flex-col items-start justify-start '>
						{error ? (
							<div className='flex items-center mb-[.75rem]'>
								<img src={svg4} alt='info icon' />
								<span className='forgot_text !text-[red] ml-[.5rem] mt-[.5px]'>
									Fill all reqired fields before proceeding
								</span>
							</div>
						) : null}
						<div className='grid grid-cols-1 w-full'>
							<Form.Item
								name='brnName'
								label='Branch Name'
								rules={[
									{
										required: true,
										message: 'Field is required',
									},
								]}>
								<Input className='input' />
							</Form.Item>

							<Form.Item
								name='brnCode'
								label='Branch Abbreviation Name'
								rules={[
									{
										required: true,
										message: 'Please add branch abbreviation code',
									},
								]}>
								<Input className='input' />
							</Form.Item>

							<Form.Item
								name='brnKraPin'
								label='KRA PIN'
								rules={[
									{
										required: true,
										message: 'Field is required',
									},
								]}>
								<Input className='input' />
							</Form.Item>

							<Form.Item
								name='brnRegCertNo'
								label='Business Registration Number'
								rules={[
									{
										required: false,
										message: 'Field is required',
									},
								]}>
								<Input className='input' />
							</Form.Item>

							<Form.Item
								validateTrigger={['onBlur']}
								name='brnEmail'
								label='Work Email'
								rules={[
									{
										required: false,
										message: 'Field is required',
									},
									{
										type: 'email',
										message: 'Enter a valid email address',
									},
								]}>
								<Input type='email' className='input' />
							</Form.Item>

							<Form.Item
								name='brnCellPhone'
								label='Work Phone'
								rules={[
									{
										required: false,
										message: 'Field is required',
									},
								]}>
								<PhoneInput
									country='ke'
									onlyCountries={['ke']}
									countryCodeEditable={false}
									className='input'
								/>
							</Form.Item>
						</div>
					</div>
				</div>

				<div className='w-full flex flex-col justify-center items-center'>
					<div className='text-center mt-10 w-[299px]'>
						<button onClick={() => next()} type='button' className='cstm-btn'>
							Next
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
