import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import SideBarClosed from './SideBarClosed';
import SideBarOpen from './SideBarOpen';

export default function SideBar({role}) {
	const { collapsed } = useSelector((state) => state.setup);

	useEffect(() => {}, [collapsed]);

	if (collapsed || window.innerWidth < 1024) return <SideBarClosed role={role} />;

	return <SideBarOpen role={role} />;
}
