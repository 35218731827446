import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { toggleCollapse } from '../features/setup/setupSlice';
import { MenuUnfoldOutlined } from '@ant-design/icons';

export default function SideBarOpen({ role }) {
	const dispatch = useDispatch();

	const { collapsed } = useSelector((state) => state.setup);

	useEffect(() => {}, [collapsed]);

	return (
		<>
			<div className='flex flex-col h-[100vh]  w-[16.6875rem] pl-[.41rem] pr-[1.7rem] bg-white sticky top-0'>
				<div className='w-full flex justify-center items-center'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='234'
						height='1'
						viewBox='0 0 234 1'
						fill='none'>
						<path d='M0.5 0.5H233.75' stroke='url(#paint0_linear_668_6803)' />
						<defs>
							<linearGradient
								id='paint0_linear_668_6803'
								x1='0.5'
								y1='0.5'
								x2='231.5'
								y2='0.5'
								gradientUnits='userSpaceOnUse'>
								<stop stop-color='#E0E1E2' stop-opacity='0' />
								<stop offset='0.5' stop-color='#E0E1E2' />
								<stop offset='1' stop-color='#E0E1E2' stop-opacity='0.15625' />
							</linearGradient>
						</defs>
					</svg>
				</div>

				<div className='flex flex-col mt-[1.41rem] w-full'>
					<div className='flex items-center justify-end w-full h-full py-3.5'>
						<button onClick={() => dispatch(toggleCollapse())}>
							<MenuUnfoldOutlined size={40} />
						</button>
					</div>

					<NavLink
						to={
							role === 'SPA'
								? '/spa/dashboard'
								: role === 'ADMIN'
								? '/admin/dashboard'
								: role === 'MANAGER'
								? '/executive/dashboard'
								: '/user/dashboard'
						}
						className='nav-item'>
						<div className='nav-icon'>
							<svg
								width='15'
								height='16'
								viewBox='0 0 15 16'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<g id='IONIcon/H/home/default' clip-path='url(#clip0_668_6801)'>
									<path
										id='Vector'
										d='M7.66264 3.46736C7.61903 3.42563 7.56099 3.40234 7.50063 3.40234C7.44027 3.40234 7.38224 3.42563 7.33862 3.46736L1.94507 8.61979C1.92216 8.64171 1.90394 8.66804 1.8915 8.69719C1.87906 8.72635 1.87267 8.75773 1.87271 8.78942L1.87183 13.6252C1.87183 13.8738 1.9706 14.1123 2.14641 14.2881C2.32223 14.4639 2.56069 14.5627 2.80933 14.5627H5.62475C5.74907 14.5627 5.8683 14.5133 5.95621 14.4254C6.04412 14.3375 6.0935 14.2182 6.0935 14.0939V10.1095C6.0935 10.0474 6.1182 9.98777 6.16215 9.94381C6.2061 9.89986 6.26572 9.87517 6.32788 9.87517H8.67163C8.73379 9.87517 8.7934 9.89986 8.83736 9.94381C8.88131 9.98777 8.906 10.0474 8.906 10.1095V14.0939C8.906 14.2182 8.95539 14.3375 9.0433 14.4254C9.1312 14.5133 9.25043 14.5627 9.37475 14.5627H12.189C12.4377 14.5627 12.6761 14.4639 12.8519 14.2881C13.0277 14.1123 13.1265 13.8738 13.1265 13.6252V8.78942C13.1265 8.75773 13.1202 8.72635 13.1077 8.69719C13.0953 8.66804 13.0771 8.64171 13.0541 8.61979L7.66264 3.46736Z'
										fill='white'
									/>
									<path
										id='Vector_2'
										d='M14.3821 7.65381L12.1907 5.55732V2.37598C12.1907 2.25166 12.1413 2.13243 12.0534 2.04452C11.9655 1.95661 11.8463 1.90723 11.722 1.90723H10.3157C10.1914 1.90723 10.0722 1.95661 9.98427 2.04452C9.89637 2.13243 9.84698 2.25166 9.84698 2.37598V3.31348L8.15011 1.69102C7.99132 1.53047 7.75518 1.43848 7.50001 1.43848C7.24571 1.43848 7.01016 1.53047 6.85138 1.69131L0.619933 7.65322C0.437706 7.829 0.414855 8.11816 0.580675 8.30859C0.622315 8.35666 0.6733 8.39575 0.730528 8.42349C0.787756 8.45122 0.850029 8.46703 0.913558 8.46993C0.977087 8.47284 1.04054 8.46278 1.10006 8.44039C1.15958 8.41799 1.21392 8.38371 1.25978 8.33965L7.33888 2.53066C7.38249 2.48894 7.44053 2.46565 7.50089 2.46565C7.56125 2.46565 7.61928 2.48894 7.6629 2.53066L13.7426 8.33965C13.8321 8.42553 13.9521 8.4724 14.0762 8.46999C14.2002 8.46757 14.3183 8.41606 14.4044 8.32676C14.5843 8.14043 14.5693 7.83281 14.3821 7.65381Z'
										fill='white'
									/>
								</g>
								<defs>
									<clipPath id='clip0_668_6801'>
										<rect
											width='15'
											height='15'
											fill='white'
											transform='translate(-0.000244141 0.5)'
										/>
									</clipPath>
								</defs>
							</svg>
						</div>
						<span className='nav-text ml-[.75rem]'>Dashboard</span>
					</NavLink>

					<span className='nav-header mt-[1.87rem] ml-[1rem]'>Setup</span>

					<NavLink to='/setup/users' className='nav-item'>
						<div className='nav-icon'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='15'
								height='15'
								viewBox='0 0 24 24'
								fill='none'>
								<path
									d='M19.63 3.64836C19.5138 3.55438 19.3781 3.48763 19.2327 3.45302C19.0873 3.41841 18.9361 3.41681 18.79 3.44836C17.7214 3.6723 16.6183 3.67515 15.5486 3.45674C14.4789 3.23833 13.4653 2.80327 12.57 2.17836C12.4026 2.06223 12.2037 2 12 2C11.7963 2 11.5974 2.06223 11.43 2.17836C10.5348 2.80327 9.52108 3.23833 8.45137 3.45674C7.38166 3.67515 6.27857 3.6723 5.21001 3.44836C5.06394 3.41681 4.91267 3.41841 4.76731 3.45302C4.62194 3.48763 4.48618 3.55438 4.37001 3.64836C4.25399 3.74247 4.16053 3.86139 4.0965 3.99637C4.03247 4.13135 3.9995 4.27896 4.00001 4.42836V11.8784C3.99912 13.3121 4.34078 14.7253 4.99654 16.0003C5.6523 17.2753 6.60319 18.3752 7.77001 19.2084L11.42 21.8084C11.5894 21.9289 11.7921 21.9937 12 21.9937C12.2079 21.9937 12.4106 21.9289 12.58 21.8084L16.23 19.2084C17.3968 18.3752 18.3477 17.2753 19.0035 16.0003C19.6592 14.7253 20.0009 13.3121 20 11.8784V4.42836C20.0005 4.27896 19.9675 4.13135 19.9035 3.99637C19.8395 3.86139 19.746 3.74247 19.63 3.64836ZM18 11.8784C18.0008 12.9931 17.7353 14.0919 17.2257 15.0834C16.716 16.0748 15.977 16.9302 15.07 17.5784L12 19.7684L8.93001 17.5784C8.02304 16.9302 7.28399 16.0748 6.77435 15.0834C6.26472 14.0919 5.99924 12.9931 6.00001 11.8784V5.57836C8.09643 5.75779 10.196 5.27138 12 4.18836C13.804 5.27138 15.9036 5.75779 18 5.57836V11.8784Z'
									fill='#34B749'
								/>
							</svg>
						</div>
						<span className='nav-text ml-[.75rem]'>User Management</span>
					</NavLink>

					<NavLink to='/setup/branches' className='nav-item'>
						<div className='nav-icon'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='15'
								height='15'
								viewBox='0 0 24 24'
								fill='none'>
								<path
									d='M19.63 3.64836C19.5138 3.55438 19.3781 3.48763 19.2327 3.45302C19.0873 3.41841 18.9361 3.41681 18.79 3.44836C17.7214 3.6723 16.6183 3.67515 15.5486 3.45674C14.4789 3.23833 13.4653 2.80327 12.57 2.17836C12.4026 2.06223 12.2037 2 12 2C11.7963 2 11.5974 2.06223 11.43 2.17836C10.5348 2.80327 9.52108 3.23833 8.45137 3.45674C7.38166 3.67515 6.27857 3.6723 5.21001 3.44836C5.06394 3.41681 4.91267 3.41841 4.76731 3.45302C4.62194 3.48763 4.48618 3.55438 4.37001 3.64836C4.25399 3.74247 4.16053 3.86139 4.0965 3.99637C4.03247 4.13135 3.9995 4.27896 4.00001 4.42836V11.8784C3.99912 13.3121 4.34078 14.7253 4.99654 16.0003C5.6523 17.2753 6.60319 18.3752 7.77001 19.2084L11.42 21.8084C11.5894 21.9289 11.7921 21.9937 12 21.9937C12.2079 21.9937 12.4106 21.9289 12.58 21.8084L16.23 19.2084C17.3968 18.3752 18.3477 17.2753 19.0035 16.0003C19.6592 14.7253 20.0009 13.3121 20 11.8784V4.42836C20.0005 4.27896 19.9675 4.13135 19.9035 3.99637C19.8395 3.86139 19.746 3.74247 19.63 3.64836ZM18 11.8784C18.0008 12.9931 17.7353 14.0919 17.2257 15.0834C16.716 16.0748 15.977 16.9302 15.07 17.5784L12 19.7684L8.93001 17.5784C8.02304 16.9302 7.28399 16.0748 6.77435 15.0834C6.26472 14.0919 5.99924 12.9931 6.00001 11.8784V5.57836C8.09643 5.75779 10.196 5.27138 12 4.18836C13.804 5.27138 15.9036 5.75779 18 5.57836V11.8784Z'
									fill='#34B749'
								/>
							</svg>
						</div>
						<span className='nav-text ml-[.75rem]'>Branches</span>
					</NavLink>

					<span className='nav-header mt-[1.87rem] ml-[1rem]'>
						ACCOUNT PAGES
					</span>

					<NavLink
						to='/onboarding-new-institution'
						className='nav-item mt-[1.5rem]'>
						<div className='nav-icon'>
							<svg
								width='15'
								height='15'
								viewBox='0 0 15 15'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<g id='IONIcon/D/document/default'>
									<path
										id='Vector'
										d='M12.5391 6.5625H8.4375C8.06454 6.5625 7.70685 6.41434 7.44313 6.15062C7.17941 5.88689 7.03125 5.52921 7.03125 5.15625V1.05469C7.03125 1.02361 7.0189 0.9938 6.99693 0.971823C6.97495 0.949847 6.94514 0.9375 6.91406 0.9375H4.21875C3.72147 0.9375 3.24456 1.13504 2.89292 1.48667C2.54129 1.83831 2.34375 2.31522 2.34375 2.8125V12.1875C2.34375 12.6848 2.54129 13.1617 2.89292 13.5133C3.24456 13.865 3.72147 14.0625 4.21875 14.0625H10.7812C11.2785 14.0625 11.7554 13.865 12.1071 13.5133C12.4587 13.1617 12.6562 12.6848 12.6562 12.1875V6.67969C12.6562 6.64861 12.6439 6.6188 12.6219 6.59682C12.5999 6.57484 12.5701 6.5625 12.5391 6.5625Z'
										fill='#34B749'
									/>
									<path
										id='Vector_2'
										d='M12.2818 5.52612L8.06865 1.31294C8.06046 1.30479 8.05004 1.29925 8.03871 1.29701C8.02737 1.29477 8.01563 1.29593 8.00495 1.30034C7.99428 1.30476 7.98514 1.31223 7.9787 1.32182C7.97226 1.33141 7.9688 1.34269 7.96875 1.35425V5.15727C7.96875 5.28159 8.01814 5.40082 8.10604 5.48873C8.19395 5.57664 8.31318 5.62602 8.4375 5.62602H12.2405C12.2521 5.62598 12.2634 5.62251 12.273 5.61607C12.2825 5.60963 12.29 5.6005 12.2944 5.58982C12.2988 5.57914 12.3 5.5674 12.2978 5.55607C12.2955 5.54473 12.29 5.53431 12.2818 5.52612Z'
										fill='#34B749'
									/>
								</g>
							</svg>
						</div>
						<span className='nav-text ml-[.75rem]'>Registration form</span>
					</NavLink>

					<NavLink to='/institutions' className='nav-item mt-[.5rem]'>
						<div className='nav-icon'>
							<svg
								width='15'
								height='15'
								viewBox='0 0 15 15'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<g id='IONIcon/D/document/default'>
									<path
										id='Vector'
										d='M12.5391 6.5625H8.4375C8.06454 6.5625 7.70685 6.41434 7.44313 6.15062C7.17941 5.88689 7.03125 5.52921 7.03125 5.15625V1.05469C7.03125 1.02361 7.0189 0.9938 6.99693 0.971823C6.97495 0.949847 6.94514 0.9375 6.91406 0.9375H4.21875C3.72147 0.9375 3.24456 1.13504 2.89292 1.48667C2.54129 1.83831 2.34375 2.31522 2.34375 2.8125V12.1875C2.34375 12.6848 2.54129 13.1617 2.89292 13.5133C3.24456 13.865 3.72147 14.0625 4.21875 14.0625H10.7812C11.2785 14.0625 11.7554 13.865 12.1071 13.5133C12.4587 13.1617 12.6562 12.6848 12.6562 12.1875V6.67969C12.6562 6.64861 12.6439 6.6188 12.6219 6.59682C12.5999 6.57484 12.5701 6.5625 12.5391 6.5625Z'
										fill='#34B749'
									/>
									<path
										id='Vector_2'
										d='M12.2818 5.52612L8.06865 1.31294C8.06046 1.30479 8.05004 1.29925 8.03871 1.29701C8.02737 1.29477 8.01563 1.29593 8.00495 1.30034C7.99428 1.30476 7.98514 1.31223 7.9787 1.32182C7.97226 1.33141 7.9688 1.34269 7.96875 1.35425V5.15727C7.96875 5.28159 8.01814 5.40082 8.10604 5.48873C8.19395 5.57664 8.31318 5.62602 8.4375 5.62602H12.2405C12.2521 5.62598 12.2634 5.62251 12.273 5.61607C12.2825 5.60963 12.29 5.6005 12.2944 5.58982C12.2988 5.57914 12.3 5.5674 12.2978 5.55607C12.2955 5.54473 12.29 5.53431 12.2818 5.52612Z'
										fill='#34B749'
									/>
								</g>
							</svg>
						</div>
						<span className='nav-text ml-[.75rem]'>Institutions</span>
					</NavLink>
				</div>
			</div>
		</>
	);
}
