import { Checkbox, Form, Input, Popconfirm, Select, Spin, Table } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { userRoles, userStatus } from '../../data';
import { save } from '../../features/save/saveSlice';
import { fetchUsers } from '../../features/fetch/fetchSlice';

export default function UserManagement({ next }) {
	const dispatch = useDispatch();
	const [form] = Form.useForm();

	const { user } = useSelector((state) => state.auth);
	const { saving } = useSelector((state) => state.save);
	const { users, loading } = useSelector((state) => state.fetch);

	const selectRef = useRef(null);

	const view = useRef(null);

	const [dataSource, setDataSource] = useState(users);
	const [deleteArray, setdeleteArray] = useState([]);

	const [editingKey, setEditingKey] = useState('');

	const isEditing = (record) =>
		record?.key === editingKey || record?.usrId === editingKey;

	const edit = (record) => {
		form.setFieldsValue({
			...record,
		});
		setEditingKey(record.key);
	};
	const cancel = () => {
		setEditingKey('');
	};

	const EditableContext = React.createContext(null);

	const EditableRow = ({ index, ...props }) => {
		return (
			<Form form={form} component={false}>
				<EditableContext.Provider value={form}>
					<tr {...props} />
				</EditableContext.Provider>
			</Form>
		);
	};

	const EditableCell = ({
		editing,
		dataIndex,
		title,
		inputType,
		record,
		index,
		children,
		...restProps
	}) => {
		const inputRef = useRef(null);
		let childNode = children;
		if (editing) {
			childNode = editing ? (
				dataIndex === 'role' ? (
					<Form.Item
						className='table-form-item'
						style={{
							margin: 0,
						}}
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
						name={dataIndex}>
						<Select
							autoFocus
							id='select-focus'
							className='select-borderless'
							variant='borderless'
							ref={selectRef || inputRef}
							suffixIcon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'>
									<path
										d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
										fill='#212121'
									/>
								</svg>
							}
							optionFilterProp='children'
							options={userRoles.map((item) => {
								return {
									label: item.label,
									value: item.value,
								};
							})}
						/>
					</Form.Item>
				) : dataIndex === 'usrStatus' ? (
					<Form.Item
						className='table-form-item'
						style={{
							margin: 0,
						}}
						name={dataIndex}>
						<Select
							autoFocus
							id='select-focus'
							className='select-borderless'
							variant='borderless'
							ref={selectRef || inputRef}
							suffixIcon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'>
									<path
										d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
										fill='#212121'
									/>
								</svg>
							}
							optionFilterProp='children'
							options={userStatus.map((item) => {
								return {
									label: item.label,
									value: item.value,
								};
							})}
						/>
					</Form.Item>
				) : (
					<Form.Item
						className='table-form-item'
						style={{
							margin: 0,
						}}
						rules={[
							{
								required:
									dataIndex === 'email' || dataIndex === 'phoneNumber'
										? true
										: false,
								message: 'Field is required',
							},
						]}
						name={dataIndex}>
						<Input
							id='input-focus'
							className='input-borderless'
							variant='borderless'
							ref={inputRef}
						/>
					</Form.Item>
				)
			) : (
				<div
					className='editable-cell-value-wrap cursor-pointer'
					style={{
						padding: '12px 8px',
					}}>
					{children}
				</div>
			);
		}
		return <td {...restProps}>{childNode}</td>;
	};

	async function handleFetch() {
		await dispatch(fetchUsers());
	}

	async function handleMultipleDelete() {
		await deleteArray?.forEach((item) => {
			toast('Deleted successful');
		});
	}

	const handleAdd = async () => {
		const newData = {
			email: '',
			firstname: '',
			lastname: ' ',
			phoneNumber: '',
			role: 'USER',
			createdDate: '',
			key: dataSource?.length + 1,
		};

		await setDataSource([...dataSource, newData]);
		await edit(newData);
	};

	const defaultColumns = [
		{
			title: 'Email',
			dataIndex: 'email',
			// sorter: (a, b) => a.email - b.email,
			editable: true,
		},
		{
			title: 'First Name',
			dataIndex: 'firstname',
			key: 'firstname',
			sorter: (a, b) => a - b,
			editable: true,
		},
		{
			title: 'Last Name',
			dataIndex: 'lastname',
			key: 'lastname',
			sorter: (a, b) => a - b,
			editable: true,
		},
		{
			title: 'Phone Number',
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
			sorter: (a, b) => a - b,
			editable: true,
		},
		{
			title: 'Role',
			dataIndex: 'role',
			key: 'role',
			sorter: (a, b) => a - b,
			editable: true,
		},
		{
			title: 'Status',
			dataIndex: 'usrStatus',
			key: 'usrStatus',
			sorter: (a, b) => a - b,
		},
		{
			title: 'Created Date',
			dataIndex: 'createdDate',
			key: 'createdDate',
			sorter: (a, b) => a - b,
			render: (item) => (
				<span>{item ? moment(item).format('MMMM YYYY') : ''}</span>
			),
		},
		{
			title: 'Action',
			render: (_, record) => {
				const editable = isEditing(record);
				return editable ? (
					<div className='flex flex-row-reverse items-center gap-x-[.75rem]'>
						<button
							disabled={saving}
							className='cstm-btn !py-[2px] !px-[7px]'
							onClick={() => handleSave(record.key || record?.usrId)}>
							{saving ? <Spin /> : 'Save'}
						</button>

						<button
							disabled={saving}
							className='cstm-btn-borderless !p-[2px]'
							onClick={cancel}>
							Cancel
						</button>
					</div>
				) : (
					<button
						className='green-border-btn !p-[2px]'
						onClick={() => edit(record)}>
						Edit
					</button>
				);
			},
		},
	];

	const handleSave = async (key) => {
		try {
			const row = await form.validateFields();
			const newData = [...dataSource];
			const index = newData.findIndex(
				(item) => key === item?.key || key === item?.usrId
			);

			if (index > -1) {
				const item = newData[index];
				newData.splice(index, 1, {
					...item,
					...row,
				});

				let saveObj = {
					...row,
					url: '/api/v2/users',
				};

				await dispatch(save(saveObj));

				await handleFetch();

				setDataSource(newData);
				setEditingKey('');
			} else {
				newData.push(row);
				setDataSource(newData);
				setEditingKey('');
			}
		} catch (errInfo) {
			console.log('Validate Failed:', errInfo);
		}
	};

	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell,
		},
	};
	const columns = defaultColumns.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				// handleSave,
				editing: isEditing(record),
			}),
		};
	});

	useEffect(() => {
		// if (dataSource?.length === 0) {
		// 	document.getElementById('addBtn')?.click();
		// }
	}, [dataSource]);

	useEffect(() => {
		setDataSource(users);
	}, [users]);

	useEffect(() => {
		view.current?.scrollIntoView({
			top: 0,
		});
		handleFetch();
	}, []);

	return (
		<>
			<div ref={view} className='mt-[52.5px] '>
				<div className='flex justify-between items-center w-full'>
					<span className='dash-head'>User Management</span>
				</div>
				<section>
					<Table
						loading={loading}
						className='mt-[44.5px]'
						size={'middle'}
						bordered={false}
						columns={columns}
						components={components}
						dataSource={dataSource}
						// scroll={{
						// 	x: 1000,
						// }}
						pagination={{
							defaultPageSize: 5,
							hideOnSinglePage: true,
							pageSizeOptions: [10, 20, 50, 100],
							onChange: cancel,
						}}
						title={
							deleteArray?.length > 1
								? () => {
										return (
											<>
												<button
													onClick={handleMultipleDelete}
													className='bg-transparent flex items-center border-none'>
													<svg
														xmlns='http://www.w3.org/2000/svg'
														width='25'
														height='25'
														viewBox='0 0 20 20'
														fill='none'>
														<path
															d='M16.875 3.75H13.75V3.125C13.75 2.62772 13.5525 2.15081 13.2008 1.79917C12.8492 1.44754 12.3723 1.25 11.875 1.25H8.125C7.62772 1.25 7.15081 1.44754 6.79917 1.79917C6.44754 2.15081 6.25 2.62772 6.25 3.125V3.75H3.125C2.95924 3.75 2.80027 3.81585 2.68306 3.93306C2.56585 4.05027 2.5 4.20924 2.5 4.375C2.5 4.54076 2.56585 4.69973 2.68306 4.81694C2.80027 4.93415 2.95924 5 3.125 5H3.75V16.25C3.75 16.5815 3.8817 16.8995 4.11612 17.1339C4.35054 17.3683 4.66848 17.5 5 17.5H15C15.3315 17.5 15.6495 17.3683 15.8839 17.1339C16.1183 16.8995 16.25 16.5815 16.25 16.25V5H16.875C17.0408 5 17.1997 4.93415 17.3169 4.81694C17.4342 4.69973 17.5 4.54076 17.5 4.375C17.5 4.20924 17.4342 4.05027 17.3169 3.93306C17.1997 3.81585 17.0408 3.75 16.875 3.75ZM7.5 3.125C7.5 2.95924 7.56585 2.80027 7.68306 2.68306C7.80027 2.56585 7.95924 2.5 8.125 2.5H11.875C12.0408 2.5 12.1997 2.56585 12.3169 2.68306C12.4342 2.80027 12.5 2.95924 12.5 3.125V3.75H7.5V3.125ZM15 16.25H5V5H15V16.25ZM8.75 8.125V13.125C8.75 13.2908 8.68415 13.4497 8.56694 13.5669C8.44973 13.6842 8.29076 13.75 8.125 13.75C7.95924 13.75 7.80027 13.6842 7.68306 13.5669C7.56585 13.4497 7.5 13.2908 7.5 13.125V8.125C7.5 7.95924 7.56585 7.80027 7.68306 7.68306C7.80027 7.56585 7.95924 7.5 8.125 7.5C8.29076 7.5 8.44973 7.56585 8.56694 7.68306C8.68415 7.80027 8.75 7.95924 8.75 8.125ZM12.5 8.125V13.125C12.5 13.2908 12.4342 13.4497 12.3169 13.5669C12.1997 13.6842 12.0408 13.75 11.875 13.75C11.7092 13.75 11.5503 13.6842 11.4331 13.5669C11.3158 13.4497 11.25 13.2908 11.25 13.125V8.125C11.25 7.95924 11.3158 7.80027 11.4331 7.68306C11.5503 7.56585 11.7092 7.5 11.875 7.5C12.0408 7.5 12.1997 7.56585 12.3169 7.68306C12.4342 7.80027 12.5 7.95924 12.5 8.125Z'
															fill='#C8001B'
														/>
													</svg>

													<span className='paragraph_paragraph_2 !text-[#C8001B] ml-3'>
														Delete selected
													</span>
												</button>
											</>
										);
								  }
								: null
						}
						footer={() => {
							return (
								<>
									<button
										id='addBtn'
										onClick={handleAdd}
										className='bg-transparent flex items-center border-none'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='25'
											height='24'
											viewBox='0 0 25 24'
											fill='none'>
											<path
												fill-rule='evenodd'
												clip-rule='evenodd'
												d='M3.2207 12C3.2207 11.5858 3.55649 11.25 3.9707 11.25H20.4707C20.8849 11.25 21.2207 11.5858 21.2207 12C21.2207 12.4142 20.8849 12.75 20.4707 12.75H3.9707C3.55649 12.75 3.2207 12.4142 3.2207 12Z'
												fill='#2E7D32'
											/>
											<path
												fill-rule='evenodd'
												clip-rule='evenodd'
												d='M12.2207 3C12.6349 3 12.9707 3.33579 12.9707 3.75V20.25C12.9707 20.6642 12.6349 21 12.2207 21C11.8065 21 11.4707 20.6642 11.4707 20.25V3.75C11.4707 3.33579 11.8065 3 12.2207 3Z'
												fill='#2E7D32'
											/>
										</svg>

										<span className='paragraph_2 !text-[#2E7D32] ml-3'>
											Add New User
										</span>
									</button>
								</>
							);
						}}
					/>
				</section>
			</div>
		</>
	);
}
