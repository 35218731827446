import { Table, Tag } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchComprehensiveCollection } from '../../../features/fetch/fetchSlice';
import moment from 'moment/moment';

export default function ComprehensiveCollectionsTable() {
	const dispatch = useDispatch();
	const { comprehensiveCollection } = useSelector((state) => state.fetch);

	async function handleFetch() {
		await dispatch(fetchComprehensiveCollection());
	}

	useEffect(() => {
		handleFetch();
	}, []);

	useEffect(() => {}, [comprehensiveCollection]);

	const columns = [
		{
			title: 'Ministry',
			dataIndex: 'instName',
			key: 'instName',
		},
		{
			title: 'Agency',
			dataIndex: 'apfApplicationType',
			key: 'apfApplicationType',
		},
		{
			title: 'Service',
			key: 'serviceName',
			dataIndex: 'serviceName',
		},
		{
			title: 'Totals',
			dataIndex: 'collection',
			key: 'collection',
		},
		{
			title: 'Collections',
			dataIndex: 'collection',
			key: 'collection',
		},
		{
			title: 'Targets',
			dataIndex: 'target',
			key: 'target',
		},
		{
			title: 'Date updated',
			render: () => <span>{moment().format('LLL')}</span>,
		},
		{
			title: 'Action',
			key: 'action',
			render: (item) => (
				<button
					// onClick={() => handleViewApplication(item)}
					className='view_btn'>
					View
				</button>
			),
		},
	];

	return (
		<>
			<div className='flex flex-col'>
				<div className='flex justify-between items-center'>
					<span className='dash-head'>Comprehensive collections</span>
				</div>

				<div className=''>
					<section>
						<Table
							className='mt-[1.31rem]'
							pagination={{
								defaultPageSize: 5,
								hideOnSinglePage: true,
								pageSizeOptions: [10, 20, 50, 100],
							}}
							columns={columns}
							dataSource={comprehensiveCollection}
						/>
					</section>
				</div>
			</div>
		</>
	);
}
