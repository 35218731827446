import CollectionPerServiceGraph from './CollectionPerServiceGraph';
import MonthlyCollectionSummaryGraph from './MonthlyCollectionSummaryGraph';

export default function CollectionOverviewCharts() {
	return (
		<>
			<div className='flex flex-col'>
				<div className='flex justify-between items-center'>
					<span className='dash-head'>Collections overview</span>
				</div>

				<div
					className='flex flex-shrink flex-col xl:flex-row xl:gap-x-[.87rem]'>
					<CollectionPerServiceGraph />
					<MonthlyCollectionSummaryGraph />
				</div>
			</div>
		</>
	);
}
