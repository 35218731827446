import InstitutionsTable from "./tables/InstitutionsTable";

export default function Institutions() {
	return (
		<>
			<div className='mt-[52.5px] flex flex-col mr-[3.29rem] mb-[7rem]'>
				<span className='dash-head'>Institutions</span>

				<div className='mt-[44.5px] flex items-center gap-[2rem] flex-wrap w-full'>
					<InstitutionsTable />
				</div>
			</div>
		</>
	);
}
