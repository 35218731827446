import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	collapsed: false,
};

export const setupSlice = createSlice({
	name: 'setup',
	initialState,
	reducers: {
		toggleCollapse: (state) => {
			state.collapsed = !state.collapsed;
		},
	},
});

export default setupSlice.reducer;
export const { toggleCollapse } = setupSlice.actions;
