import React, { useEffect, useRef } from 'react';
import { Form, Input, Select } from 'antd';
import PhoneInput from 'react-phone-input-2';
import svg4 from '../../../assets/svg/svg4.svg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProvinces } from '../../../features/fetch/fetchSlice';

export default function LocationStep({ next, error, prev }) {
	const dispatch = useDispatch();

	const { provinces } = useSelector((state) => state.fetch);

	const ref = useRef();

	async function handleFetchProvinces() {
		await dispatch(fetchProvinces());
	}

	useEffect(() => {
		if (error) {
			ref.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'start',
			});
		}
	}, [error]);

	useEffect(() => {
		handleFetchProvinces();
	}, []);

	return (
		<>
			<div
				ref={ref}
				className='flex flex-col items-center justify-center w-full'>
				<div className='mt-[1.44rem]  p-[3.5rem] flex flex-col justify-center w-full bg-white'>
					<div className='flex items-center'>
						<div className='w-[3.45rem]'>
							<div className='bg-[#EDF8FF] w-[3.5rem] h-[3.5rem] rounded-full flex justify-center items-center'>
								<span className='paragraph_paragraph_bb08'>2</span>
							</div>
						</div>

						<span className='heading_5 ml-[1.31rem]'>Where are you based?</span>
					</div>
					<div className='mt-[2.25rem] px-[1.37rem] flex flex-col items-start justify-start w-full'>
						{error ? (
							<div className='flex items-center mb-[.75rem]'>
								<img src={svg4} alt='info icon' />
								<span className='forgot_text !text-[red] ml-[.5rem] mt-[.5px]'>
									Fill all reqired fields before proceeding
								</span>
							</div>
						) : null}
						<Form.Item
							name='instCounty'
							label='Province'
							rules={[
								{
									required: false,
									message: 'Please select your county',
								},
							]}>
							<Select
								suffixIcon={
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'>
										<path
											d='M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z'
											fill='#212121'
										/>
									</svg>
								}
								showSearch
								style={{
									width: '100%',
									height: '44px',
								}}
								optionFilterProp='children'
								filterOption={(input, option) =>
									(option?.label?.toLocaleLowerCase() ?? '').includes(
										input?.toLocaleLowerCase()
									)
								}
								filterSort={(optionA, optionB) =>
									(optionA?.label ?? '')
										.toLowerCase()
										.localeCompare((optionB?.label ?? '').toLowerCase())
								}
								options={provinces?.map((item) => {
									return {
										label: item?.prnName,
										value: item?.prnName,
									};
								})}
							/>
						</Form.Item>

						<div className='grid grid-cols-1 lg:grid-cols-2 gap-5 w-full'>
							<Form.Item
								name='instRegion'
								label='City/State/Region'
								rules={[
									{
										required: false,
										message: 'Please select your county',
									},
								]}>
								<Input className='input' />
							</Form.Item>

							<Form.Item
								name='instPostalCode'
								label='Postal/Zip Code'
								rules={[
									{
										required: false,
										message: 'Please select your county',
									},
								]}>
								<Input className='input' />
							</Form.Item>
						</div>

						<Form.Item
							name='instPhysicalAddress'
							label='Address'
							rules={[
								{
									required: false,
									message: 'Please select your county',
								},
							]}>
							<Input className='input' />
						</Form.Item>
					</div>
				</div>

				<div className='w-full flex flex-col justify-center items-center'>
					<div className='text-center mt-10 w-[299px]'>
						<button onClick={() => next()} type='button' className='cstm-btn'>
							Next
						</button>
					</div>

					<div className='mt-[20px] mb-[50px] w-[299px]'>
						<button
							type='button'
							className='cstm-btn-borderless'
							onClick={() => prev()}>
							Back
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
