import { Outlet } from 'react-router-dom';
import Header from './layout/Header';

export default function Root() {
	return (
		<div className='flex flex-col'>
			<Header />
			<div className=''>
				<Outlet />
			</div>
		</div>
	);
}
