import React, { useEffect, useRef, useState } from 'react';
import { Form, Steps, theme } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AgencyStep from './onboarding_steps/AgencyStep';
import LocationStep from './onboarding_steps/LocationStep';
import SettlementStep from './onboarding_steps/SettleMentStep';
import AdminStep from './onboarding_steps/AdminStep';
import { save } from '../../features/save/saveSlice';
import toast from 'react-hot-toast';

export default function Register() {
	const [form] = Form.useForm();
	const formRef = useRef(null);
	// const { token } = theme.useToken();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [current, setCurrent] = useState(0);
	const [error, seterror] = useState(false);
	const [formData, setFormData] = useState({});

	const next = () => {
		form
			.validateFields({ validateOnly: true })
			.then((formValues) => {
				setCurrent(current + 1);
				seterror(false);
			})
			.catch((error) => {
				seterror(true);
			});
	};

	const prev = () => {
		setCurrent(current - 1);
	};

	const handleChange = (values) => {
		setFormData((prevData) => ({
			...prevData,
			...values,
		}));
	};

	const steps = [
		{
			title: <span className='stepper-title'>Institution Details</span>,
			content: <AgencyStep error={error} next={next} />,
			icon: (
				<span className='w-[24px] h-[24px] rounded-full border-2 border-[#D1D5DB]'></span>
			),
		},
		{
			title: <span className='stepper-title'>Location Details</span>,
			content: <LocationStep error={error} next={next} prev={prev} />,
			icon: (
				<span className='w-[24px] h-[24px] rounded-full border-2 border-[#D1D5DB]'></span>
			),
		},
		{
			title: <span className='stepper-title'>Settlement Details</span>,
			content: <SettlementStep error={error} next={next} prev={prev} />,
			icon: (
				<span className='w-[24px] h-[24px] rounded-full border-2 border-[#D1D5DB]'></span>
			),
		},
		{
			title: <span className='stepper-title'>Admin Details</span>,
			content: <AdminStep error={error} prev={prev} />,
			icon: (
				<span className='w-[24px] h-[24px] rounded-full border-2 border-[#D1D5DB]'></span>
			),
		},
	];

	const customDot = (dot, { status, index }) => {
		let icon;
		if (status === 'process') {
			icon = (
				<div className='w-fit h-fit z-[1000] bg-white p-[11px] rounded-full border-2 border-[#14B04C] flex items-center justify-center'>
					<span className='w-[10px] h-[10px] rounded-full bg-[#14B04C]'></span>
				</div>
			);
		} else if (status === 'finish') {
			icon = (
				<div className='w-fit h-fit z-[1000] bg-white p-[11px] rounded-full border-2 border-[#14B04C] flex items-center justify-center'>
					<span className='w-[10px] h-[10px] rounded-full bg-[#14B04C]'></span>
				</div>
			);
		} else {
			icon = steps[index].icon;
		}

		return (
			<div className='custom-step'>
				{icon}
				<div className='custom-step-title'>{steps[index].title}</div>
			</div>
		);
	};

	const items = steps.map((item) => ({
		key: item.title,
		title: '',
	}));

	const contentStyle = {
		textAlign: 'center',
		// color: token.colorTextTertiary,
		marginTop: 56,
	};

	const onFinish = async (data) => {
		let usrObj = {
			firstname: formData.firstname,
			lastname: formData.lastname,
			email: formData.email,
			phoneNumber: formData.phoneNumber,
			role: 'ADMIN',
		};

		let instObj = {
			instName: formData.instName,
			instKraPin: formData.instKraPin,
			instRegCertNo: formData.instRegCertNo,
			instEmail: formData.instEmail,
			instCellPhone: formData.instCellPhone,
			instCounty: formData.instCounty,
			instRegion: formData.instRegion,
			instPostalCode: formData.instPostalCode,
			instPhysicalAddress: formData.instPhysicalAddress,
			instCode: formData.instCode,
		};

		let bankObj = {
			stName: formData.stName,
			stNumber: formData.stNumber,
			stDuration: formData.stDuration,
		};

		let saveObj = {
			...instObj,
			adminUser: usrObj,
			settlementAccount: bankObj,
			url: '/api/v1/inst',
		};
		const res = await dispatch(save(saveObj));

		if (res?.payload?.success) {
			await toast.success('Institution onboarding successful');
			await navigate("/institutions")
		} else {
			toast.error('An error occured while onboarding. Please try again later');
		}
	};

	async function handleDropdownOptions() {}

	useEffect(() => {
		handleDropdownOptions();
	}, []);

	return (
		<>
			<div className='flex items-center justify-center w-full'>
				<div className='w-full lg:w-[61.25rem]'>
					<div className='flex flex-col w-full px-3 lg:px-[52px] p-3'>
						<h2 className='heading_1 mt-[2rem] text-center'>
							Institution Onboarding
						</h2>

						<Form
							layout='vertical'
							ref={formRef}
							name='control-ref'
							onFinish={onFinish}
							style={{
								width: '100%',
							}}
							onValuesChange={(values) => {
								handleChange(values);
							}}
							form={form}>
							<div className='lg:mt-[40px] mt-0 mb-[5rem]'>
								<Steps
									current={current}
									progressDot={customDot}
									items={items}
									className=''
								/>
								<div style={contentStyle}>{steps[current].content}</div>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
}
