import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	loading: false,
	provinces: [],
	institutions: [],
	overview: {},
	instTransactions: [],
	monthlyCollectionSummary: [],
	collectionPerService: [],
	comprehensiveCollection: [],
	users: [],
	branches: [],
};

export const fetchProvinces = createAsyncThunk(
	'fetchSlice/fetchProvinces',
	async () => {
		const res = await axiosInstance
			.get(`${url}/api/v1/public/province`)
			.then((res) => res.data?.data?.result);
		return res;
	}
);

export const fetchInstitutions = createAsyncThunk(
	'fetchSlice/fetchInstitutions',
	async () => {
		const res = await axiosInstance
			.get(`${url}/api/v1/inst`)
			.then((res) => res.data?.data?.result);
		return res;
	}
);

export const fetchOverview = createAsyncThunk(
	'fetchSlice/fetchOverview',
	async () => {
		const res = await axiosInstance
			.post(`${url}/dashboard/overview`, {
				startDate: null,
				endDate: null,
				filter: '',
				instId: null,
				serviceId: null,
				status: '',
			})
			.then((res) => res.data?.data);
		return res;
	}
);

export const fetchInstTransactions = createAsyncThunk(
	'fetchSlice/fetchInstTransactions',
	async () => {
		const res = await axiosInstance
			.post(`${url}/dashboard/transactionByInstitution`, {
				startDate: null,
				endDate: null,
				filter: '',
				instId: null,
				serviceId: null,
				status: '',
			})
			.then((res) => res.data?.data?.transByInst);
		return res;
	}
);

export const fetchMonthlyCollectionSummary = createAsyncThunk(
	'fetchSlice/fetchMonthlyCollectionSummary',
	async () => {
		const res = await axiosInstance
			.post(`${url}/dashboard/monthlyCollectionSummary`, {
				startDate: null,
				endDate: null,
				filter: '',
				instId: null,
				serviceId: null,
				status: '',
			})
			.then((res) => res.data?.data);
		return res;
	}
);

export const fetchCollectionPerService = createAsyncThunk(
	'fetchSlice/fetchCollectionPerService',
	async () => {
		const res = await axiosInstance
			.post(`${url}/dashboard/collectionPerService`, {
				startDate: null,
				endDate: null,
				filter: '',
				instId: null,
				serviceId: null,
				status: '',
			})
			.then((res) => res.data?.data);
		return res;
	}
);

export const fetchComprehensiveCollection = createAsyncThunk(
	'fetchSlice/fetchComprehensiveCollection',
	async () => {
		const res = await axiosInstance
			.post(`${url}/dashboard/comprehensivecollection`, {
				startDate: null,
				endDate: null,
				filter: '',
				instId: null,
				serviceId: null,
				status: '',
			})
			.then((res) => res.data?.data?.comprehensivecollection);
		return res;
	}
);

export const fetchUsers = createAsyncThunk(
	'fetchSlice/fetchUsers',
	async () => {
		const res = await axiosInstance
			.get(`${url}/api/v2/users`)
			.then((res) => res.data?.data?.result);
		return res;
	}
);

export const fetchBranches = createAsyncThunk(
	'fetchSlice/fetchBranches',
	async () => {
		const res = await axiosInstance
			.get(`${url}/api/v2/brn`)
			.then((res) => res.data?.data?.result);
		return res;
	}
);

export const fetchSlice = createSlice({
	name: 'fetch',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(fetchBranches.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchBranches.fulfilled, (state, action) => {
				state.loading = false;
				state.branches = action.payload;
			})
			.addCase(fetchBranches.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchUsers.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchUsers.fulfilled, (state, action) => {
				state.loading = false;
				state.users = action.payload;
			})
			.addCase(fetchUsers.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchComprehensiveCollection.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchComprehensiveCollection.fulfilled, (state, action) => {
				state.loading = false;
				state.comprehensiveCollection = action.payload;
			})
			.addCase(fetchComprehensiveCollection.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchCollectionPerService.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchCollectionPerService.fulfilled, (state, action) => {
				state.loading = false;
				state.collectionPerService = action.payload;
			})
			.addCase(fetchCollectionPerService.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchMonthlyCollectionSummary.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchMonthlyCollectionSummary.fulfilled, (state, action) => {
				state.loading = false;
				state.monthlyCollectionSummary = action.payload;
			})
			.addCase(fetchMonthlyCollectionSummary.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchInstTransactions.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchInstTransactions.fulfilled, (state, action) => {
				state.loading = false;
				state.instTransactions = action.payload;
			})
			.addCase(fetchInstTransactions.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchOverview.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchOverview.fulfilled, (state, action) => {
				state.loading = false;
				state.overview = action.payload;
			})
			.addCase(fetchOverview.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchInstitutions.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchInstitutions.fulfilled, (state, action) => {
				state.loading = false;
				state.institutions = action.payload;
			})
			.addCase(fetchInstitutions.rejected, (state) => {
				state.loading = false;
			})

			.addCase(fetchProvinces.pending, (state) => {
				state.loading = true;
			})
			.addCase(fetchProvinces.fulfilled, (state, action) => {
				state.loading = false;
				state.provinces = action.payload;
			})
			.addCase(fetchProvinces.rejected, (state) => {
				state.loading = false;
			});
	},
});

export default fetchSlice.reducer;
export const {} = fetchSlice.actions;
