import { useDispatch, useSelector } from 'react-redux';
import InvoiceChart from './branch_chart/InvoiceChart';
import PayChart from './branch_chart/PayChart';
import WithdrawChart from './branch_chart/WithdrawChart';
import CollectionOverviewCharts from './dash_cards/CollectionOverviewCharts';
import InvoiceCard from './dash_cards/InvoiceCard';
import MainCard from './dash_cards/MainCard';
import ComprehensiveCollectionsTable from './tables/ComprehensiveCollectionsTable';
import InstitutionTransactionTable from './tables/InstitutionTransactionTable';
import { fetchOverview } from '../../features/fetch/fetchSlice';
import { useEffect } from 'react';

export default function InstitutionDashboard() {
	const dispatch = useDispatch();
	const { overview } = useSelector((state) => state.fetch);

	async function handleFetch() {
		await dispatch(fetchOverview());
	}

	useEffect(() => {
		handleFetch();
	}, []);

	useEffect(() => {}, [overview]);

	return (
		<>
			<div className='mt-[52.5px] flex flex-col mb-[7rem]'>
				<span className='dash-head'>Overview</span>

				<div className='mt-[44.5px] flex items-center gap-[2rem] flex-shrink-0 flex-wrap w-full'>
					<MainCard item={overview?.PaymentOverview} />
					<MainCard item={overview?.withDrawalOverView} withdrawals={true} />
					<InvoiceCard item={overview?.invoicesSummary} />
				</div>

				<div className='mt-[5.25rem] w-full'>
					<InstitutionTransactionTable />
				</div>

				<div className='mt-[5.25rem] flex flex-col w-full'>
					<div className='flex justify-between items-center w-full'>
						<span className='dash-head'>Institution summary</span>
					</div>
					<div className='mt-[1.5rem] flex items-center gap-[2rem] flex-wrap w-full'>
						<PayChart />
						<WithdrawChart />
						<InvoiceChart />
					</div>
				</div>

				<div className='mt-[4.75rem] flex items-center gap-[2rem] flex-wrap min-w-full w-full h-full'>
					<CollectionOverviewCharts />
				</div>

				<div className='mt-[2.53rem] w-full'>
					<ComprehensiveCollectionsTable />
				</div>
			</div>
		</>
	);
}
