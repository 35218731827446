import { Progress } from 'antd';
import { formatMoney } from '../../../utils';

export default function MainCard({ item, withdrawals }) {
	return (
		<>
			<div className='dash-card'>
				<div className='flex justify-between items-center'>
					<div className='flex flex-col'>
						<span className='dash_card_header_text'>
							{withdrawals ? 'Total Withdrawals' : 'Total Payments'}
						</span>
						<span className='dash_card_cash_text mt-[3px]'>
							{formatMoney(item?.totalPayments)}
						</span>
					</div>

					<Progress
						strokeWidth={9}
						strokeColor='#48BB78'
						trailColor='#F63435'
						className='dash-card-progress'
						type='circle'
						percent={item?.percentagePaid}
						format={() => (
							<span
								className={`${
									item?.percentagePaid < 0 ? '!text-[#F63435]' : ''
								} dash_card_percent_text`}>
								{item?.percentagePaid < 0 ? '' : '+'}
								{item?.percentagePaid}%
							</span>
						)}
					/>
				</div>

				<div className='flex flex-col gap-x-[53px] w-full'>
					<div className='flex items-center justify-between'>
						<div className='flex items-center w-[300px]'>
							<div className='w-[12px] h-[12px] rounded-full bg-[#48BB78]'></div>
							<span className='dash_card_cash_text_v2 ml-[6px]'>
								Success: {formatMoney(item?.active)}
							</span>
						</div>

						<div className='flex items-center pr-[81px]'>
							<div className='w-[12px] h-[12px] rounded-full bg-[#EA9D2C]'></div>
							<span className='dash_card_cash_text_v2 ml-[6px]'>
								Cancelled: {formatMoney(item?.cancelled)}
							</span>
						</div>
					</div>

					<div className='flex items-center mt-[7px]'>
						<div className='flex items-center pr-[81px]'>
							<div className='w-[12px] h-[12px] rounded-full bg-[#7792B7]'></div>
							<span className='dash_card_cash_text_v2 ml-[6px]'>
								Pending: {formatMoney(item?.pendingAllocation)}
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
