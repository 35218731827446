import { Table, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInstitutions } from '../../../features/fetch/fetchSlice';
import { useEffect } from 'react';
import moment from 'moment/moment';

export default function InstitutionsTable() {
	const dispatch = useDispatch();

	const { institutions, loading } = useSelector((state) => state.fetch);

	async function handleFetchInstitutions() {
		await dispatch(fetchInstitutions());
	}

	const columns = [
		{
			title: 'Institution Name',
			dataIndex: 'instName',
			key: 'instName',
		},
		{
			title: 'Abbreviation Code',
			dataIndex: 'instCode',
		},
		{
			title: 'KRA PIN',
			key: 'instKraPin',
			dataIndex: 'instKraPin',
		},
		{
			title: 'Business Email',
			dataIndex: 'instEmail',
			key: 'instEmail',
		},
		{
			title: 'Business Phone Number',
			dataIndex: 'instCellPhone',
			key: 'instCellPhone',
		},
		{
			title: 'Address',
			dataIndex: 'instPhysicalAddress',
			key: 'instPhysicalAddress',
		},
		{
			title: 'Created Date',
			dataIndex: 'instCreatedDate',
			key: 'instCreatedDate',
			render: (item) => <span>{moment(item).format('LLL')}</span>,
		},
		// {
		// 	title: 'Action',
		// 	key: 'action',
		// 	render: (item) => (
		// 		<button
		// 			// onClick={() => handleViewApplication(item)}
		// 			className='view_btn'>
		// 			View
		// 		</button>
		// 	),
		// },
	];

	useEffect(() => {
		handleFetchInstitutions();
	}, []);

	useEffect(() => {
	}, [institutions]);

	return (
		<>
			<div className='w-full flex flex-col'>
				<div className='flex justify-between items-center w-full'>
					<span className='dash-head'>All Institutions</span>
				</div>

				<Table
					loading={loading}
					className='mt-[1.31rem]'
					pagination={false}
					columns={columns}
					dataSource={institutions}
				/>
			</div>
		</>
	);
}
