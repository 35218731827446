import React, { useEffect } from 'react';
import { MenuFoldOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCollapse } from '../features/setup/setupSlice';
import { Link } from 'react-router-dom';

function getItem(label, key, icon, children, type) {
	return {
		key,
		icon,
		children,
		label,
		type,
	};
}

const items = [
	getItem(
		<Link to='/dashboard'>Dashboard</Link>,
		'dashboard',
		<svg
			width='24'
			height='25'
			viewBox='0 0 15 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='IONIcon/H/home/default' clip-path='url(#clip0_668_6801)'>
				<path
					id='Vector'
					d='M7.66264 3.46736C7.61903 3.42563 7.56099 3.40234 7.50063 3.40234C7.44027 3.40234 7.38224 3.42563 7.33862 3.46736L1.94507 8.61979C1.92216 8.64171 1.90394 8.66804 1.8915 8.69719C1.87906 8.72635 1.87267 8.75773 1.87271 8.78942L1.87183 13.6252C1.87183 13.8738 1.9706 14.1123 2.14641 14.2881C2.32223 14.4639 2.56069 14.5627 2.80933 14.5627H5.62475C5.74907 14.5627 5.8683 14.5133 5.95621 14.4254C6.04412 14.3375 6.0935 14.2182 6.0935 14.0939V10.1095C6.0935 10.0474 6.1182 9.98777 6.16215 9.94381C6.2061 9.89986 6.26572 9.87517 6.32788 9.87517H8.67163C8.73379 9.87517 8.7934 9.89986 8.83736 9.94381C8.88131 9.98777 8.906 10.0474 8.906 10.1095V14.0939C8.906 14.2182 8.95539 14.3375 9.0433 14.4254C9.1312 14.5133 9.25043 14.5627 9.37475 14.5627H12.189C12.4377 14.5627 12.6761 14.4639 12.8519 14.2881C13.0277 14.1123 13.1265 13.8738 13.1265 13.6252V8.78942C13.1265 8.75773 13.1202 8.72635 13.1077 8.69719C13.0953 8.66804 13.0771 8.64171 13.0541 8.61979L7.66264 3.46736Z'
					fill='#34B749'
				/>
				<path
					id='Vector_2'
					d='M14.3821 7.65381L12.1907 5.55732V2.37598C12.1907 2.25166 12.1413 2.13243 12.0534 2.04452C11.9655 1.95661 11.8463 1.90723 11.722 1.90723H10.3157C10.1914 1.90723 10.0722 1.95661 9.98427 2.04452C9.89637 2.13243 9.84698 2.25166 9.84698 2.37598V3.31348L8.15011 1.69102C7.99132 1.53047 7.75518 1.43848 7.50001 1.43848C7.24571 1.43848 7.01016 1.53047 6.85138 1.69131L0.619933 7.65322C0.437706 7.829 0.414855 8.11816 0.580675 8.30859C0.622315 8.35666 0.6733 8.39575 0.730528 8.42349C0.787756 8.45122 0.850029 8.46703 0.913558 8.46993C0.977087 8.47284 1.04054 8.46278 1.10006 8.44039C1.15958 8.41799 1.21392 8.38371 1.25978 8.33965L7.33888 2.53066C7.38249 2.48894 7.44053 2.46565 7.50089 2.46565C7.56125 2.46565 7.61928 2.48894 7.6629 2.53066L13.7426 8.33965C13.8321 8.42553 13.9521 8.4724 14.0762 8.46999C14.2002 8.46757 14.3183 8.41606 14.4044 8.32676C14.5843 8.14043 14.5693 7.83281 14.3821 7.65381Z'
					fill='white'
				/>
			</g>
			<defs>
				<clipPath id='clip0_668_6801'>
					<rect
						width='15'
						height='15'
						fill='white'
						transform='translate(-0.000244141 0.5)'
					/>
				</clipPath>
			</defs>
		</svg>
	),
	getItem(
		'Account Pages',
		'sub1',
		<svg
			width='25'
			height='25'
			viewBox='0 0 15 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='IONIcon/D/document/default'>
				<path
					id='Vector'
					d='M12.5391 6.5625H8.4375C8.06454 6.5625 7.70685 6.41434 7.44313 6.15062C7.17941 5.88689 7.03125 5.52921 7.03125 5.15625V1.05469C7.03125 1.02361 7.0189 0.9938 6.99693 0.971823C6.97495 0.949847 6.94514 0.9375 6.91406 0.9375H4.21875C3.72147 0.9375 3.24456 1.13504 2.89292 1.48667C2.54129 1.83831 2.34375 2.31522 2.34375 2.8125V12.1875C2.34375 12.6848 2.54129 13.1617 2.89292 13.5133C3.24456 13.865 3.72147 14.0625 4.21875 14.0625H10.7812C11.2785 14.0625 11.7554 13.865 12.1071 13.5133C12.4587 13.1617 12.6562 12.6848 12.6562 12.1875V6.67969C12.6562 6.64861 12.6439 6.6188 12.6219 6.59682C12.5999 6.57484 12.5701 6.5625 12.5391 6.5625Z'
					fill='#34B749'
				/>
				<path
					id='Vector_2'
					d='M12.2818 5.52612L8.06865 1.31294C8.06046 1.30479 8.05004 1.29925 8.03871 1.29701C8.02737 1.29477 8.01563 1.29593 8.00495 1.30034C7.99428 1.30476 7.98514 1.31223 7.9787 1.32182C7.97226 1.33141 7.9688 1.34269 7.96875 1.35425V5.15727C7.96875 5.28159 8.01814 5.40082 8.10604 5.48873C8.19395 5.57664 8.31318 5.62602 8.4375 5.62602H12.2405C12.2521 5.62598 12.2634 5.62251 12.273 5.61607C12.2825 5.60963 12.29 5.6005 12.2944 5.58982C12.2988 5.57914 12.3 5.5674 12.2978 5.55607C12.2955 5.54473 12.29 5.53431 12.2818 5.52612Z'
					fill='#34B749'
				/>
			</g>
		</svg>,
		[
			getItem(
				<Link to='/onboarding-new-institution'>Registration form</Link>,
				'new'
			),
			getItem(<Link to='/institutions'>Institutions</Link>, 'inst'),
		]
	),
];

export default function SideBarClosed() {
	const dispatch = useDispatch();

	const { collapsed } = useSelector((state) => state.setup);

	useEffect(() => {}, [collapsed]);

	return (
		<>
			<div
				style={{
					borderInlineEnd: '1px solid rgba(5, 5, 5, 0.06)',
				}}
				className='flex flex-col h-[100vh] sticky top-0 bg-white'>
				<div className='flex items-center justify-center py-3.5'>
					<button onClick={() => dispatch(toggleCollapse())}>
						<MenuFoldOutlined size={25} />
					</button>
				</div>

				<Menu
					defaultSelectedKeys={['dash', '/dashboard']}
					defaultOpenKeys={[]}
					mode='inline'
					inlineCollapsed={collapsed || window.innerWidth < 1024}
					items={items}
				/>
			</div>
		</>
	);
}
